
/**
 * Objeto que contiene la configuración disponible para el app-table
 */
export class TableConfig {
    actions?: TableAction[] = []; //Array de TableAction: botones con función que se colocan sobre la tabla
    headers?: TableHeader[] = []; //Array de TableHeader: propiedades que se mostrarán de una lista de objetos.
    canResetHeaders?: boolean ; //Propiedad para mostrar/ocultar función para acomodar los headers con su orden original
    extra?: Extra;

    showEdit?: boolean = false;
    
    showCreate?: boolean = false;
    createBadge?: string;

    showDelete?: boolean = false;
    showMassDelete?: boolean = false;
    showSearch?: boolean = false;
    showSorting?: boolean = false;
    showSend?: boolean = false;
    showAnswer?: boolean = false;
    showPrintPDF?: boolean = true;
    showEmailConfig?: boolean = false;

    isSelectable?: boolean = false;
}
export class TableAction {
    icon: string;
    title: string;
    action: any;
    color?: string;
    isRead? : boolean;
}
export class TableHeader {
    displayName: string;
    property: string;
    showHeader?: boolean = true;
    isDate? : boolean;
    hasFormat? : boolean;
    format? : any;
    iconOptions?: any[];

    order?:string = null;
    orderControl? : boolean = null;

    localSearch? : boolean;
    search?:string = null;
    searchValue?:string = null;
    searchOptions? : any[];
    searchControl? : boolean = false;

    isToggle? : boolean;
    toggleValues? : any;

    important? : boolean;
    

    isRead?: boolean;

    infoButton? : boolean;
    infoButtonAction? : any;
    infoButtonOnRow? : boolean;

    toolTipProperty? : string;
    toolTipText? : string;

    enum?: any;
    action?: any
    actionName? : string;
    icon?: string
    isSvg? : boolean;

    color?: string
    size?: string

    noMove?: boolean
}

export class Extra{
    content : string;
    link?: any;
    style?: string;
}

export class IconOption{
    icon?: string;
    name: string;
    value : any;
    color?: string;
}

/**
 * Permite ordenar una propiedad (FieldName) ascendente (OrderAscending = true) o descendentemente (OrderAscending = false)
 */
export class OrderBy{
    FieldName:string;
    OrderAscending:boolean;
}

/**
 * Permite filtrar una propiedad (FieldName) en base a un valor (FilterValue)
 */
export class FilterBy{
    FieldName: string;
    FilterValue: any;
    fromSelect?: boolean;
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoaderService {

    private loaderState: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Estado del loader
     */
    _loaderState: Observable<boolean> = this.loaderState.asObservable();

    constructor() {}

    /**
     * Mostrar el loader indefinidamente
     */
    show() {
        this.loaderState.next(true);
    }

    /**
     * Ocultar el loader 
     */
    hide() {
        setTimeout(() => {
            this.loaderState.next(false);

        }, 100);
    }
}
export class PagingParams {
    totalItems: number = 0;
    pageNumber: number = 1;

    pageSize: number = 15;
    totalPages: number = 0;
    items: number = 0;
}

// export class PagingParams{
//     pageNumber: number = 1;
//     pageSize: number = 10;

//     totalPages: number = 0;
//     items: number = 0;
//     totalItems: number = 0;

// }
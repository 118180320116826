import { Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';

export class Survey extends FormClass {

    @InputProp()
    surveyID: number;

    @InputProp({
        placeholder: 'Nombre',
        inputType: InputType.TEXT,
        validators: [Validators.required],
    })
    name: string;

    @InputProp({
        placeholder: 'Descripción',
        inputType: InputType.TEXT_AREA,
        validators: [Validators.required],
    })
    description: string;

    @InputProp({
        placeholder: 'Tipo de encuesta',
        inputType: InputType.SELECT,
        validators: [Validators.required],
        selectConfig: {
            propName: 'typeList',
            value: 'typeID',
            name: 'name'
        }
    })
    type: number;

    @InputProp()
    typeList: any = [];

    initNewForm(survey: Survey) {
        var formGroup = this.initForm(survey);
        return formGroup;
    }

    initEditForm(survey: Survey) {
        var formGroup = this.initForm(survey);
        return formGroup;
    }

}

export class SurveyFilters {
    name: string;
    description: string;
    filterByType: number;
    filterBySectionType: number;
}

export class SurveyIncludes {
    sections: boolean;
    cuestions: boolean;
}


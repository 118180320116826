import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Injector } from '@angular/core';
import * as moment from 'moment';
import { FilterBy, IconOption, OrderBy, TableConfig, TableHeader } from 'src/app/_shared/_models/table.model';
import * as XLSX from 'xlsx';

import { PagingParams } from '../_models/paging.model';
import { SessionService } from '../_services/session.service';
import { ErrorStatusService } from '../_services/error-status.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormDialogComponent } from '../_components/form-dialog/form-dialog.component';
import { LoaderService } from '../_services/loader.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnyARecord } from 'dns';

const myExcel = require('myexcel');

declare var html2canvas;
declare var jsPDF;

@Component({
    selector: 'app-table',
    templateUrl:    './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {


    /**
     * Filtros generales 
     * @param filters
     * 
     * @example
     * filters: EmployeeFilters
    */
    @Input()
    filters //Filtros generales 

    /**
     * Arreglo de FilterBy, para filtrado por header
     */
    @Input()
    filterBy: FilterBy[] = [];


    /**
     * Arreglo de OrderBy, para ordenado ascendente o descendente
     */
    @Input()
    orderBy: OrderBy[] = [];

    /**
     * Nombre de la sección actual. Sirve como nombre de reportes generados
     */
    @Input()
    sectionName: string;

    /**
     * Parametros de la tabla
     */
    @Input()
    config: TableConfig;

    @Input()
    rows = []; // 

    @Input()
    trackBy: string = "" //Propiedad para identificar por renglon

    @Input()
    paging: PagingParams = new PagingParams(); //Paraemtros de paginado

    //@Input()
    //checkValues : any[] = null //Valores por checar, puede ser temporal para adecuar los registros con campos requeridos faltantes

    @Output()
    onChanged: EventEmitter<any> = new EventEmitter<any>(); //Emisor de evento de cambio

    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>(); //Emisor de eventos al editar un registro

    @Output()
    onCreate: EventEmitter<any> = new EventEmitter<any>(); //Emisor de eventos al crear un registro

    @Output()
    onDelete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onSend: EventEmitter<any> = new EventEmitter<any>(); //Emisor de eventos al enviar (correos)    

    @Output()
    onSelectItem: EventEmitter<any> = new EventEmitter<any>(); //Emisor de eventos al seleccionar un registro

    toggleHeaderMenu: boolean = false; //Bandera para mostrar/esconder los headers disponibles

    selection = { //Objeto auxiliar para selección de registros
        selected: {}
    }

    valueChange //Variable para identificar cambio de vista

    @Input()
    whenButton = false;

    originalHeaders : TableHeader[] = []

    lastPaging : PagingParams = null;

    private dialog: MatDialog;


    constructor(
        private elementRef: ElementRef,
        private cdr: ChangeDetectorRef, //Detector de cambios
        private sessionService: SessionService, //Servicio de sesión,
        private injector: Injector,
        private errorService: ErrorStatusService,
        private loaderService : LoaderService,
        private sanitizer: DomSanitizer,
        private router : Router
    ) {
    }

    clickEvent(event, ignore? : any)
    {
        //columns
        if(ignore) {
            event.stopPropagation();
            this.toggleHeaderMenu = !this.toggleHeaderMenu
            return
        }

        var classes : string = event.target.className;
        var inContainer = 
            classes.includes("columns") || classes.includes("mat-checkbox") || classes.includes("reset-button") ||
            classes.includes("mat-button")

        if(this.toggleHeaderMenu && !inContainer) {
            this.toggleHeaderMenu = !this.toggleHeaderMenu
        } 
    }

    ngOnChanges(changes?: import("@angular/core").SimpleChanges): void {
        if (this.rows && this.trackBy && this.config.isSelectable) { //Se setean
            this.rows.forEach(x => {
                if (this.selection.selected[x[this.trackBy].value]) {
                    x['isSelected'] = true;
                }
            })

            if (this.valueChange != this.sectionName) {
                this.rows.forEach(x => {
                    delete x['isSelected'];
                })

                this.selection.selected = {};
            }
        }
    }

    /**
     * Limpia la propiedad isSelected de cada header (variables de selección)
     */
    cleanChecks() {
        this.isAllSelected = false;

        this.rows.forEach(x => {
            delete x['isSelected'];
        })

        this.selection.selected = {};
    }

    ngOnInit(): void {
        this.valueChange = this.sectionName;
        if (this.sectionName == 'Tabla')
            return;

        if(this.config.canResetHeaders)
            this.originalHeaders = Object.assign([],this.config.headers)

        var config = localStorage.getItem(this.sectionName)
        if (config) {
            var array: any[] = JSON.parse(config);
            var newHeaders: TableHeader[] = [];

            array.forEach(a => {
                var aux = this.config.headers.find(h => {
                    if (h.displayName == a.displayName && h != null) {
                        h['checked'] = true;
                        return h
                    }
                })
                if (aux) {
                    aux.showHeader = a.showHeader;
                    newHeaders.push(aux);
                }
            })

            //Cuando se añadieron más headers en otra versión
            if (newHeaders.length < this.config.headers.length) {
                this.config.headers.forEach(h => {
                    if (!h["checked"]) {
                        newHeaders.push(Object.assign(new TableHeader(), h))
                    }
                })
            }

            this.config.headers = newHeaders

            this.config.headers.map(h => {
                if (h.noMove)
                    h.showHeader = true
            })

            this.config.headers.forEach(h => {
                delete h['checked'];
            })
        }
    }

    //SIN USO
    findAction(action: string): boolean {
        return this.config.actions.find(i => i.icon == action) != null;
    }

    /**
     * Realiza el cambio de página y emite el evento para actualizar la información
     * @param number Página solicitada
     */
    onChange(number) {
        this.paging.pageNumber = number;
        this.onChanged.emit();
    }

    /**
     * Cambia parametros de paginado
     * @param event 
     */
    onChangePageSize(event) {
        if (event.value == 0)
            this.paging.pageSize = this.paging.totalItems;
        else
            this.paging.pageSize = event.value;
        this.onChanged.emit();
    }

    /**
     * Regresa el total de paginas disponibles
     */
    getPages() {
        return new Array(this.paging.totalPages)
    }


    /**
     * 
     * @param row 
     * @param header 
     */
    getRowValue(row, header) {
        var tmp = this.resolve(header.property, row);
        var val = tmp instanceof Object ? tmp.value : tmp;
        if (header.enum) {
            val = header.enum[val]
        }
        if (header.isDate && val) {
            val = moment(new Date(val)).format("DD/MM/YYYY");
        }
        if (header.hasFormat && val) {
            val = header.format(val);
        }
        return val;
    }

    onCheckItem(item) {
        var value = item[this.trackBy].value || item[this.trackBy];
        if (item['isSelected']) {
            this.selection.selected[value] = item;
        }
        else {
            delete this.selection.selected[value];
        }
    }

    resolve(path, obj = self, separator = '.') {
        var properties = Array.isArray(path) ? path : path.split(separator)
        return properties.reduce((prev, curr) => prev && prev[curr], obj)
    }

    async onPrintPDF() {

        if (!this.sessionService.checkClaims(2)) {
            this.fileError()
            return
        }

        var doc = await this.getPDF();

        doc.autoPrint();
        //This is a key for printing
        doc.output('dataurlnewwindow');

    }

    /**
     * Despues de generar el archivo, lo descarga con el nombre de la sección o página 
     */
    async downloadPDF() {
        if (!this.sessionService.checkClaims(2)) {
            this.fileError()
            return
        }

        var doc = await this.getPDF();

        this.showPdf(doc.output('arraybuffer'), this.sectionName.replace(/\s/g, '_') + '.pdf');
        
        //doc.save(this.sectionName.replace(/\s/g, '_') + '.pdf');


    }

    /**
     * Permite visualizar un archivo PDF y también descargarlo con un nombre específico
     * @param buf Información como blob o un array de buffers
     * @param fileName Nombre del archivo
     */
    async showPdf(buf : any, fileName : string) {

        this.dialog = this.injector.get(MatDialog);

        try {
            this.loaderService.show()

            var meblob = new Blob([buf], { type: "application/pdf" });
            var blob_url = window.URL.createObjectURL(meblob);

            var san = this.sanitizer.bypassSecurityTrustResourceUrl(blob_url);

            var documentData = {
                name: "Resultado",
                message: "",
                file: san,
                fileName: fileName
            }

            this.loaderService.hide();
            await this.dialog.open(FormDialogComponent, { data: documentData, panelClass: 'panel-nooverflow', height: '800px', width: '1000px' }).afterClosed().toPromise()

        } catch (err) {
            if (!this.errorService.getError()) {

                var message = 'Ocurrio un error al mostrar el archivo.';
                if (err.error && err.error.code == 420) {
                    var message = 'Renueva tu licencia para poder realizar esta acción.';
                }
                var errorData = {
                    name: 'Error',
                    message: message,
                    group: null,
                    template: null,
                    noCancel: true
                }
                this.loaderService.hide()
                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise()
                this.loaderService.show()
            }
        }
        finally {
            this.loaderService.hide();
        }
    }

    
    getTableHeaders(): any[] {
        return [this.visibleHeaders().filter(h => !h.action).map(h => h.displayName)];
    }

    getTableBody(): any[] {
        return this.rows.map(r => {
            return [
                ...this.visibleHeaders()
                    .filter(h => !h.action) // Quitar los que tengan acciones
                    .map(h => this.getRowValue(r, h)
                    )
            ];
        })

    }

    /**
     * Generador de pdf en base a la información contenida en la tabla
     */
    async getPDF() {
        var doc = new jsPDF('l', 'mm', "letter");

        var logo = await this.sessionService.getLogo();
        
        if (logo) {
            try {
                var img = new Image()
                var array = logo.split('.')
                array = array[array.length - 1].split('?');
                img.src = logo
                doc.addImage(img, array[0], 20, 10, 44, 29);
            }
            catch (e) { }
        }



        //Header
        doc.setDrawColor(0);
        doc.setFillColor("#0f4c83");
        doc.roundedRect(80, 10, 250, 25, 8, 8, 'FD')

        doc.setFontSize(18);

        var xMiddle = (doc.internal.pageSize.width / 2);

        doc.setTextColor("#FFFFFF")
        doc.text(
            this.sectionName,
            90,
            25,
            {
                //align: 'center',
                maxWidth: 180
            }
        );

        doc.setTextColor("#000000")

        jsPDF.autoTableSetDefaults({
            headStyles: { fillColor: [15, 76, 131] },
        })

        doc.autoTable({
            startY: 50,
            head: this.getTableHeaders(),
            body: this.getTableBody()
        });

        return doc;
    }

    downloadXLSX() {
        /* generate worksheet */

        var array = [
            ...this.getTableHeaders(),
            ...this.getTableBody()
        ]

        const ws: XLSX.WorkSheet = XLSX.utils
            .aoa_to_sheet(array);

        ws['!cols'] = fitToColumn(array);

        function fitToColumn(arrayOfArray) {
            // get maximum character of each column
            return arrayOfArray[0].map((a, i) => ({
                wch: Math.max(...arrayOfArray.map(a2 => {
                    if (!a2[i])
                        return 0;
                    else
                        return a2[i].toString().length;
                }))
            }));
        }

        /* generate workbook and add the worksheet */

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */

        XLSX.writeFile(wb, this.sectionName + '.xlsx', { bookType: 'xlsx', bookSST: true, type: 'binary' });
    }

    testXLSX() {
        if (!this.sessionService.checkClaims(2)) {
            this.fileError()
            return
        }

        var testData = this.getTableBody()

        const excel = myExcel.new('Calibri light 10 #333333');

        const headers = this.getTableHeaders()[0];

        const formatHeader = excel.addStyle({
            /*border: 'none,none,none,thin #333333',*/
            fill: "#0f4c83",
            font: 'Calibri 12 #FFFFFF B',
            align: "L"
        });

        const formatCellI = excel.addStyle({
            align: "L",
            fill: "#cae4ed"
        });
        const formatCellP = excel.addStyle({
            align: "L",
            fill: "#ffffff"
        });

        for (let i = 0; i < headers.length; i++) {
            excel.set(0, i, 0, headers[i], formatHeader);
            excel.set(0, i, undefined, 30);
        }

        for (let i = 0; i < testData.length; i++) {
            for (let j = 0; j < headers.length; j++) {
                var value = (testData[i][j]) ? testData[i][j] : ' ';
                if ((i + 1) % 2 > 0)
                    excel.set(0, j, i + 1, value, formatCellI);
                else
                    excel.set(0, j, i + 1, value, formatCellP);
            }
        }

        excel.generate(this.sectionName + '.xlsx', () => { });
    }

    visibleHeaders() {
        if(this.config.canResetHeaders && this.originalHeaders.length == 0 && this.config.headers.length != 0 )
            this.originalHeaders = Object.assign([],this.config.headers)

        return this.config.headers.filter(h => h.showHeader);
    }

    reload() {
        this.onChanged.emit();
    }

    isAllSelected: boolean = false;
    masterToggle() {
        if (this.isAllSelected) {
            this.rows.forEach(r => {
                var value = r[this.trackBy].value || r[this.trackBy];
                r['isSelected'] = true;
                this.selection.selected[value] = r;
            });
        }
        else {
            this.rows.forEach(r => {
                r['isSelected'] = false;
            });
            this.selection.selected = {};
        }
    }

    drop(event: CdkDragDrop<any[]>) {
        var headers = this.visibleHeaders();

        var ob1 = headers[event.previousIndex];
        var ob2 = headers[event.currentIndex];

        if (!event.isPointerOverContainer || !ob1 || !ob2)
            return;

        var index1 = this.config.headers.findIndex(o => o && o.displayName === ob1.displayName); //Se cambio property por displayName, para poder repetir las proiedades con botones que las cambian
        var index2 = this.config.headers.findIndex(o => o && o.displayName === ob2.displayName);

        //Quitar obj1
        this.config.headers.splice(index1, 1);

        //Colocarlo en la otra posición
        this.config.headers.splice(index2, 0, ob1);

        this.config.headers = Object.assign([], this.config.headers);

        this.cdr.detectChanges();
    }

    onOrder(header: TableHeader, value: boolean) {
        if (!header.order)
            return

        var index = this.orderBy.findIndex(o => o.FieldName == header.order);

        if (index < 0) {
            var orderBy: OrderBy = new OrderBy();
            orderBy.FieldName = header.order;
            orderBy.OrderAscending = true
            //else
            //  orderBy.OrderAscending = !orderBy.OrderAscending
            //orderBy.OrderAscending = value
            header.orderControl = true;

            this.orderBy.push(orderBy)
        }
        else {
            /* var currentValue = this.orderBy[index].OrderAscending;
            if (currentValue == value) {
                this.orderBy.splice(index, 1);
            }
            else {
                this.orderBy[index].OrderAscending = value;
            } */

            header.orderControl = !header.orderControl;
            this.orderBy[index].OrderAscending = !this.orderBy[index].OrderAscending
            value = this.orderBy[index].OrderAscending
        }

        //console.log(this.orderBy)

        this.onChanged.emit();
    }

    onFilter(header: TableHeader, value: any) {
        if (header.localSearch) {
            //Value es el indice del arreglo que corresponde la opción
            if (!isNaN(value) && value != "" && header.searchOptions)
                value = header.searchOptions[parseInt(value)][0]

            this.onLocalFilter(header, value)
        }
        else {
            if (!header.searchValue) {
                //Si no existe valor, entonces no es necesario enviarlo
                delete this.filters[header.search];
            }
            else {
                //Si regresa un numero (indice)
                if (!isNaN(value) && value != "" && header.searchOptions)
                    value = header.searchOptions[parseInt(value)][0];

                this.filters[header.search] = value;

                /* header.searchValue = (header.searchValue).split(',') as any;
                this.filters[header.search] = header.searchValue[0]; */
            }
        }

        this.onChanged.emit();
    }

    onLocalFilter(header: TableHeader, value: string) {
        var index = this.filterBy.findIndex(o => o.FieldName == header.search);

        if (index < 0) {
            if (value === "") {
                return
            }
            var filterBy: FilterBy = new FilterBy();
            filterBy.FieldName = header.search;
            filterBy.fromSelect = false;

            if (header.searchOptions) {
                var aux = header.searchOptions.find(o => o[0] == value)[0]
                if (aux != undefined) {
                    filterBy.FilterValue = aux
                    filterBy.fromSelect = true
                }
            }
            else {
                filterBy.FilterValue = value;
            }


            this.filterBy.push(filterBy)
        }
        else {
            var currentValue = this.filterBy[index].FilterValue
            if (value === '') {
                this.filterBy.splice(index, 1);
            } else if (currentValue != value) {

                if (header.searchOptions)
                    this.filterBy[index].FilterValue = header.searchOptions.find(o => o[0] == value)[0]
                else
                    this.filterBy[index].FilterValue = value
            }
        }
    }

    onFunction(obj : any, event? : any, config? : any)
    {
        if(!obj) return this.blockFunction()

        var value = this.canExecute()

        if(config)
        {
            if(config.isRead)
            {
                value = true;
            }else if(config.action)
            {
                if(config.action.isRead) value = true;
            }
        }

        if(!value) return this.blockFunction()

        if(obj instanceof EventEmitter)
        {
            if(event)
                obj.emit(event)
            else   
                obj.emit()
        }else if(obj instanceof Function){ // or only else
            if(event)
                obj(event)
            else 
                obj()
        }
    }

    async blockFunction(){
        var catchedError = 'No tienes los permisos necesarios para realizar esta acción.'
        this.errorService.setError(catchedError)

        this.dialog = this.injector.get(MatDialog);

        var messageData = {
            name: "Error",
            message: catchedError,
            noCancel: true
        }

        const dialogRef = this.dialog.open(FormDialogComponent, { data: messageData, panelClass: 'panel-nooverflow' })

        var res = await dialogRef.afterClosed().toPromise()

        this.errorService.setError(null)
    }

    canExecute(){
        return this.sessionService.checkClaims(1);
    }

    /* checkOrder(header: TableHeader, value: boolean) {
        var index = this.orderBy.findIndex(o => o.FieldName == header.order);

        return (index < 0) ? false : this.orderBy[index].OrderAscending == value ? true : false;
    } */

    getIcon(value, iconOptions: IconOption[]) {
        return iconOptions.find(x => x.name == value)
    }

    onSelect(item) {
        //console.log(item);
        this.onSelectItem.emit(item);
    }

    toogleFilter(header: TableHeader) {
        /* if(header.searchControl)
        { */
        this.onFilter(header, header.searchValue);
        /* }
        header.searchControl = !header.searchControl */
    }

    saveConfig() {
        //Guardar configuración en localStorage
        if (this.sectionName == 'Tabla')
            return;
        var actualOrderHeaders = this.config.headers.map(h => {
            return { 'property': h.property, 'showHeader': h.showHeader, 'displayName': h.displayName }
        })

        localStorage.removeItem(this.sectionName)
        localStorage.setItem(this.sectionName, JSON.stringify(actualOrderHeaders));
    }

    getTooltip(row, header: TableHeader) {

        if (!header.toolTipProperty || !header.toolTipText)
            return null;

        var tmp = this.resolve(header.toolTipProperty, row);
        var val = tmp instanceof Object ? tmp.value : tmp;

        if (header.enum) {
            val = header.enum[val]
        }
        if (header.isDate && val) {
            val = moment(new Date(val)).format("DD/MM/YYYY");
        }

        return header.toolTipText + " " + val;
    }

    async fileError() {
        var catchedError = 'No tienes los permisos necesarios para generar archivos.'
        this.errorService.setError(catchedError)

        this.dialog = this.injector.get(MatDialog);

        var messageData = {
            name: "Error",
            message: catchedError,
            noCancel: true
        }

        const dialogRef = this.dialog.open(FormDialogComponent, { data: messageData, panelClass: 'panel-nooverflow' })

        var res = await dialogRef.afterClosed().toPromise()

        this.errorService.setError(null)
    }

    sanitizingHTML(html : string) : SafeHtml{
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    navigate(link : any)
    {
        this.router.navigate([link.route], {
            queryParams: {
                tabNum : link.params
            }

        })
    }

    resetHeaders(){
        this.config.headers = Object.assign([], this.originalHeaders)

        this.config.headers.map(x => {
            x.showHeader = true;
        })

        this.saveConfig();
        this.onChanged.emit()
        this.cdr.detectChanges()
        this.cdr.markForCheck()
    }

}

<mat-card class="mat-elevation-z5" (click)="clickEvent($event)">
    <mat-card-content>
        <section class="actions">
            <div class="button-text" *ngIf="config?.showCreate">
                <button mat-mini-fab color="white" (click)="onFunction(onCreate, 'onCreate')" [matBadge]="config.createBadge"  matBadgeSize="small"> 
                    <mat-icon color="primary">add</mat-icon>
                </button>
                <span class="small">Nuevo</span>
            </div>

            <div class="button-text">
                <button mat-mini-fab color="white" (click)="testXLSX()">
                    <mat-icon class="excell-icon">table_chart</mat-icon>
                </button>
                <span class="small">XLSX</span>

            </div>
            <div class="button-text">
                <button mat-mini-fab color="white" (click)="downloadPDF()">
                    <mat-icon color="warn">picture_as_pdf</mat-icon>
                </button>
                <span class="small">PDF</span>

            </div>
            <div class="button-text">
                <button mat-mini-fab color="white" (click)="onPrintPDF()">
                    <mat-icon class="print-icon">print</mat-icon>
                </button>
                <span class="small">Imprimir</span>
            </div>
            <div class="button-text">

                <div style="position: relative;">
                    <button mat-mini-fab color="white" (click)="clickEvent($event, 1)">
                        <mat-icon class="print-icon">playlist_add_check</mat-icon>
                    </button>

                    <section class="mat-elevation-z5 columns" *ngIf="toggleHeaderMenu"
                        style="position: absolute;top:41px;left:0;background-color: white;padding: 10px;min-width: 200px; z-index: 1;height: 250px;overflow: auto;">
                        <div class="columns" *ngFor="let header of config.headers">
                            <!-- <input type="checkbox" [(ngModel)]="header.showHeader"> -->
                            <!-- {{config.headers.lenght}} -->
                            <ng-container *ngIf="header && !header.noMove">
                                <mat-checkbox class="columns" color="primary" [(ngModel)]="header.showHeader"
                                    (ngModelChange)="saveConfig()">
                                    <span class="columns">
                                        {{header?.displayName}}
                                    </span>
                                </mat-checkbox>
                            </ng-container>
                        </div>
                        <div class="reset-button" *ngIf="config?.canResetHeaders">
                            <button mat-raised-button (click)="resetHeaders()" color="primary">Restablecer</button>
                        </div>
                    </section>
                </div>
                <span class="small">Columnas</span>

            </div>
            <div class="button-text" *ngFor="let action of config?.actions">
                <button mat-mini-fab color="white" (click)="onFunction(action.action,null,action)">
                    <mat-icon [ngStyle]="{'color': action.color}">{{action.icon}}</mat-icon>
                </button>
                <span class="small">{{action.title}}</span>
            </div>
            <div class="extras" *ngIf="config?.extra"  >
                <div [innerHTML]="sanitizingHTML(config?.extra.content)" [style]="config?.extra.style" (click)="navigate(config?.extra.link)"></div>
            </div>

            <div style="flex:1"></div>
            <mat-form-field *ngIf="paging" style="width: 100px;" >
                <mat-select [value]="paging.pageSize" (selectionChange)="onChangePageSize($event)"
                    placeholder="Mostrar registros">
                    <mat-option [value]="15">
                        15
                    </mat-option>
                    <mat-option [value]="50">
                        50
                    </mat-option>
                    <mat-option [value]="100">
                        100
                    </mat-option>
                    <mat-option [value]="0">
                        TODOS
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="button-text">
                <button mat-mini-fab color="accent" (click)="reload()">
                    <mat-icon>refresh</mat-icon>
                </button>
                <span class="small">Recargar</span>

            </div>
            <mat-form-field *ngIf="config?.showSearch">

                <input matInput placeholder="Buscar" value="">
            </mat-form-field>
        </section>

        <!-- Table -->

        <section class="table">
            <section class="table-wrapper">
                <section class="t-head">
                    <div class="row">
                        <div class="col" style="width:50px;" *ngIf="config?.isSelectable">
                            <mat-checkbox color="accent" [(ngModel)]="isAllSelected" (change)="masterToggle()">
                            </mat-checkbox>
                        </div>
                        <div style="display: flex; flex:1;" cdkDropList [cdkDropListData]="visibleHeaders()"
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                            <div cdkDrag class="col" *ngFor="let header of visibleHeaders()"
                                [ngStyle]="{'width': header.size ? header.size : '150px' , 'flex': ( '0 0 '+ header.size)}">

                                <div class="label-options">

                                    <div class="header-options">
                                        <div *ngIf="header.order" class="filter-buttons">
                                            <mat-icon *ngIf="header.orderControl == true" class="icons arrow">
                                                keyboard_arrow_up
                                            </mat-icon>
                                            <mat-icon *ngIf="header.orderControl == false " class="icons arrow">
                                                keyboard_arrow_down
                                            </mat-icon>
                                        </div>
                                        <span style="margin: 6px 0;" class="example-box label"
                                            (click)="onOrder(header,true)">{{header.displayName}}</span>
                                        <button *ngIf="header.infoButton && !header.infoButtonOnRow" style="margin: 0 10px;" mat-icon-button
                                            color="white" (click)="header.infoButtonAction()">
                                            <mat-icon>help</mat-icon>
                                        </button>
                                    </div>

                                    <div class="filter-section" *ngIf="header.search">
                                        <!-- <span class="filter"
                                            *ngIf="header.searchValue && header.searchOptions">
                                            ({{ header.searchOptions[header.searchValue][1] }})
                                        </span>

                                        <span class="filter"
                                            *ngIf="header.searchValue && !header.searchOptions">
                                            ({{ header.searchValue }})
                                        </span> -->

                                        <input *ngIf="!header.searchOptions" type="text" class="input"
                                            [(ngModel)]="header.searchValue" maxlength="30"
                                            (keyup.enter)="toogleFilter(header)">

                                        <!-- (focusout)="onFilter(header,header.searchValue)" -->

                                        <select *ngIf="header.searchOptions" class="input"
                                            [(ngModel)]="header.searchValue" (change)="toogleFilter(header)">



                                            <!-- (focusout)="onFilter(header,header.searchValue)" -->

                                            <option value="">TODOS</option>

                                            <option [value]="i" *ngFor="let o of header.searchOptions; let i=index">
                                                {{o[1]}}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div style="flex:1">

                            </div>
                            <div class="col actions" style="flex:0 0 200px;width:200px"
                                *ngIf="config?.showEdit || config?.showDelete">
                                Acciones
                            </div>
                        </div>
                    </div>
                </section>
                <section class="t-body">
                    <cdk-virtual-scroll-viewport itemSize="10">
                        <div class="row" *cdkVirtualFor="let row of rows;" (click)="onSelect(row)">
                            <!-- Selection  -->
                            <div class="col" style="flex:0 0 50px" *ngIf="config?.isSelectable">

                                <mat-checkbox color="primary" [(ngModel)]="row.isSelected" (change)="onCheckItem(row)">
                                </mat-checkbox>

                                <!-- <input type="checkbox" > -->
                            </div>
                            <!-- values -->
                            <div class="col" *ngFor="let header of visibleHeaders()"
                                [ngStyle]="{'width': header.size?header.size:'150px' , 'flex': ( '0 0 '+ header.size)}">
                                <div *ngIf="header.action">


                                    <button mat-raised-button color="primary"
                                        *ngIf="getRowValue(row,header) && !header.isToggle" (click)="onFunction(header.action, row, header)"
                                        [matTooltip]="getTooltip(row, header)" matTooltipPosition="after"
                                        matTooltipClass="custom-tooltip">
                                        <mat-icon *ngIf="header.isSvg" [svgIcon]="header.icon" color="accent">

                                        </mat-icon>
                                        <mat-icon *ngIf="!header.isSvg" color="accent">{{header.icon}}</mat-icon>

                                        {{header.actionName}}

                                    </button>
                                    <button mat-raised-button
                                        [color]="header.toggleValues[getRowValue(row,header)] ? 'primary' : 'warn'"
                                        *ngIf="header.isToggle && header.enum" (click)="onFunction(header.action, row, header)" style="min-width: 100px;
                                        ">
                                        <mat-icon *ngIf="header.isSvg" [svgIcon]="header.icon" color="accent">
                                        </mat-icon>
                                        <!-- <mat-icon *ngIf="!header.isSvg" color="white">{{header.icon}}</mat-icon> -->

                                        {{getRowValue(row,header)}}

                                    </button>


                                    <!-- <mat-slide-toggle color="primary" *ngIf="header.isSlide"
                                    [checked]="getRowValue(row,header)"  (toggleChange)="header.action(row)">
                                 </mat-slide-toggle> -->
                                </div>
                                <div *ngIf="!header.action" style="overflow: hidden; text-overflow: ellipsis;">
                                    <!-- Con icono y color -->
                                    <mat-icon
                                        *ngIf="header.iconOptions && getIcon(getRowValue(row,header), header.iconOptions)?.icon"
                                        [ngStyle]="{'color': getIcon(getRowValue(row,header), header.iconOptions)?.color}"
                                        [matTooltip]="getIcon(getRowValue(row,header), header.iconOptions)?.name"
                                        matTooltipPosition="after" matTooltipClass="custom-tooltip">
                                        {{getIcon(getRowValue(row,header), header.iconOptions)?.icon}}
                                    </mat-icon>
                                    <!-- Solo con color -->
                                    <span
                                        *ngIf="header.iconOptions && !getIcon(getRowValue(row,header), header.iconOptions)?.icon"
                                        [ngStyle]="{'color': getIcon(getRowValue(row,header), header.iconOptions)?.color}">
                                        <b>{{ getRowValue(row,header) }}</b>
                                    </span>

                                    <span *ngIf="!header.iconOptions">
                                        {{ getRowValue(row,header) }}
                                        
                                        <button *ngIf="header.infoButton && header.infoButtonOnRow && row.infoButtonOnRow" style="margin: 0 10px;" mat-icon-button
                                            color="primary" (click)="header.infoButtonAction(row.infoButtonOnRow)">
                                            <mat-icon>help</mat-icon>
                                        </button>
                                    </span>

                                </div>


                                <!-- <div *ngIf="header.iconOptions" class="status-container">
                                    <mat-icon *ngIf="getIcon(getRowValue(row,header), header.iconOptions)?.icon"
                                        [ngStyle]="{'color': getIcon(getRowValue(row,header), header.iconOptions)?.color}"
                                        [matTooltip]="getIcon(getRowValue(row,header), header.iconOptions)?.name"
                                        matTooltipPosition="after"
                                        matTooltipClass="custom-tooltip">
                                        {{getIcon(getRowValue(row,header), header.iconOptions)?.icon}}
                                    </mat-icon>

                                    <span *ngIf="!getIcon(getRowValue(row,header), header.iconOptions)?.icon"
                                        [ngStyle]="{'color': getIcon(getRowValue(row,header), header.iconOptions)?.color}">
                                        <b>{{getRowValue(row,header)}}</b>
                                    </span>
                                </div> -->
                            </div>
                            <div style="flex:1">

                            </div>
                            <!-- Static actions -->
                            <div class="col actions" style="flex:0 0 200px;width:200px"
                                *ngIf="config?.showEdit || config?.showDelete">
                                <!-- 
                                    [matTooltip]="editWarning(row)?.message" matTooltipClass="custom-tooltip"  matTooltipPosition="above"
                                    [ngStyle]="{'background-color': editWarning(row)?.color}"
                                 -->
                                <button mat-mini-fab color="primary" (click)="$event.stopPropagation();onFunction(onEdit, row)"
                                    *ngIf="config?.showEdit && !row.blockEdit">
                                    <mat-icon class="smaller-icon">edit</mat-icon>
                                </button>
                                <button mat-mini-fab color="warn" (click)="$event.stopPropagation();onFunction(onDelete, row)"
                                    *ngIf="config?.showDelete && !row.blockDelete">
                                    <mat-icon class="smaller-icon" [ngStyle]="{'color': 'white'}">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="row empty" *ngIf="rows.length == 0 || !rows">
                            No hay datos
                        </div>
                    </cdk-virtual-scroll-viewport>
                </section>
            </section>
        </section>
        <section class="footer">
            <section class="paginate-controls">
                <button mat-raised-button [disabled]="paging.pageNumber==1" (click)="onChange(paging.pageNumber-1)">
                    <mat-icon>arrow_left</mat-icon>
                </button>
                <button class="paging-number" mat-raised-button *ngFor="let p of getPages();let i =index"
                    [ngClass]="{'selected':i+1 == paging.pageNumber}" (click)="onChange(i+1)">
                    {{i+1}}
                </button>
                <button mat-raised-button [disabled]=" paging.pageNumber >= getPages().length"
                    (click)="onChange(paging.pageNumber+1)">
                    <mat-icon>arrow_right</mat-icon>
                </button>
            </section>
            <section [ngClass]="{'moving': whenButton}">
                <ng-container *ngIf="paging.totalItems != 0">
                    Mostrando registros del {{((paging.pageNumber - 1) * paging.pageSize)+1}} al
                    <!-- {{( ((paging.pageNumber) * paging.pageSize) > paging.totalItems) 
                        ? paging.totalItems 
                        : ((paging.pageNumber) * paging.pageSize)
                    }}  -->
                    {{((paging.pageNumber - 1) * paging.pageSize) + paging.items}}
                    de un total de {{paging.totalItems}}
                    registros
                </ng-container>
                <ng-container *ngIf="paging.totalItems == 0">
                    Mostrando 0 registros
                </ng-container>
            </section>
        </section>
    </mat-card-content>
</mat-card>
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseService } from '../../_services/license.service';

@Component({
    selector: 'app-license-message',
    templateUrl: './license-message.component.html',
    styleUrls: ['./license-message.component.scss']
})

export class LicenseMessageComponent {
    licenseCaducated: Observable<number>


    link;

    isTest : Observable<any>;


    constructor(
        private licenseService: LicenseService,
    ) {
        this.licenseCaducated = this.licenseService._licenseCaducated;
        this.isTest = this.licenseService._licenseValues;
        this.licenseService._licenseUrl.subscribe(r => {
            this.link = r;
        });
    }   


    getClasses(licenseCaducated) {
        var clases = [];

        if (licenseCaducated == -1)
            return [];
        if (licenseCaducated >= 0 && licenseCaducated <= 10)
            return ['active', 'error'];
        if (licenseCaducated > 10 && licenseCaducated <= 20)
            return ['active', 'warn'];
        if (licenseCaducated > 20 && licenseCaducated <= 30)
            return ['active', 'success'];
    }


 
}
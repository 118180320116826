import { Injectable, Injector } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FormDialogComponent } from '../_components/form-dialog/form-dialog.component';

@Injectable()
export class ErrorStatusService {

    private currentError: BehaviorSubject<any> = new BehaviorSubject(null);
    _currentError: Observable<any> = this.currentError.asObservable().pipe(delay(0));


    constructor(
    ) { }

    getError()
    {
        return this.currentError.getValue()
    }

    setError(error){
        this.currentError.next(error)
    }
    
    
}
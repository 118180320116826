import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ServiceBase } from '../../_shared/_helpers/form-manager.component';
import { Period, PeriodFilters, PeriodIncludes } from './period.model';

@Injectable()

export class PeriodService extends ServiceBase<Period, PeriodIncludes, PeriodFilters> {
    url = environment.server + 'api/Periods';

    constructor(
        protected http: HttpClient
    ) {
        super(Period);
    }
}
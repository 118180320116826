<app-loader></app-loader>

<app-license-message></app-license-message>


<app-navbar (onMenu)="toogleMenu()" *ngIf="session | async"></app-navbar>
<section class="page-container">
    <ng-container *ngIf="(session | async) && (navState | async) > 0">
        
        <app-sidenav (onMenu)="toogleMenu()" class="small-screens"
            [ngClass]="{'opened': toogleSidenav , 'closed' : !toogleSidenav}"></app-sidenav>
        <div class="mask" (click)="toogleMenu()"></div>
    </ng-container>



    <router-outlet></router-outlet>
</section>


<div class="version">
    <button mat-raised-button *ngIf="currentVersion != apiVersion" color="warn" (click)="update()">
        Actualizar {{currentVersion}} - > {{apiVersion}}
    </button>
    <div *ngIf="currentVersion == apiVersion">
        {{currentVersion}}
    </div>
</div>
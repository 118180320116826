import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from './_shared/_services/loader.service';
import { SessionService } from './_shared/_services/session.service';
import { VersionService } from './_shared/_services/version.service';
import { EndSessionService } from './_shared/_services/end-session.service';
import { FormDialogComponent } from './_shared/_components/form-dialog/form-dialog.component';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  static dialog;

  toogleSidenav: boolean = true;

  typeUser;

  session: Observable<any>;

  endSession: Observable<any>;

  navState: Observable<number>

  currentVersion: string;

  apiVersion: string;

  lastCheck: string = "";

  constructor(
    private sessionService: SessionService,
    private endSessionService: EndSessionService,
    private loaderService: LoaderService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private versionService: VersionService,
    private snackService: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.dialog = dialog;

    ['area', 'centro', 'departamento', 'empleado', 'empresa',
      'periodo', 'puesto', 'preguntas', 'resultados', 'seccion',
      'cuestionarios', 'encuesta', 'configuracion', 'usuario', 'encuesta-p',
      'contestar', 'enviar', 'ver', 'tabla', 'grafica', 'filtro', 'filtrado']
      .forEach(i => {
        this.matIconRegistry.addSvgIcon(
          i,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/catalogos/${i}.svg`)
        );
      })
    
      /* var resultsIcons = ['Ambiente de Trabajo',
          'Factores de la actividad',
          'Liderazgo y relaciones en el trabajo',
          'Condiciones en el ambiente de trabajo',
          'Carga de trabajo',
          'Falta de control sobre el trabajo',
          'Jornada de trabajo',
          'Interferencia en la relación trabajo-familia',
          'Liderazgo',
          'Relaciones en el trabajo',
          'Violencia',
          'Organización del tiempo de trabajo',
          'Entorno Organizacional',
          'Reconocimiento del desempeño',
          'Insuficiente sentido de pertenencia e inestabilidad'
      ].forEach(i => {
        this.matIconRegistry.addSvgIcon(
          i,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/categories/${i}.svg`)
        );
      }) */

    this.currentVersion = environment.version;
    this.apiVersion = environment.version;



    this.session = sessionService._session;

    this.endSession = endSessionService._sessionEndState;

    this.navState = sessionService._navbarState;

    //S = superuser
    this.session.subscribe((s) => {
      if (s)
        this.typeUser = this.decodeToken(s.token).LicenseType;
    })

    this.endSession.subscribe((end) => {
      if (end) {
        var sessionData = {
          name: "Sesión Expirada",
          message: "Su sesión ha expirado. Vuelva a iniciar sesión.",
          noCancel: true
        }

        this.dialog.closeAll()

        this.dialog.open(FormDialogComponent, {
          data: sessionData,
          panelClass: 'panel-nooverflow'
        })

        this.endSessionService.reset()
      }
    })



    this.checkForUpdates();
    setInterval(() => {
      this.checkForUpdates();
    }, 60000);
  }

  update() {
    //location.reload(true);
    window.location.href = window.location.href + '?' + new Date().getTime();
    window.location.reload();
  }

  async checkForUpdates() {
    try {
      this.apiVersion = (await this.versionService.getVersion().toPromise()).version;

      if (this.apiVersion != this.currentVersion && this.lastCheck != this.apiVersion) {
        this.snackService.open('Existe una nueva versión disponible', null, {
          duration: 5000
        });
      }

      this.lastCheck = this.apiVersion;
    }
    catch (ex) { }

  }

  toogleMenu() {
    //console.log(this.toogleSidenav)
    this.toogleSidenav = !this.toogleSidenav
  }

  decodeToken(token: string) {
    var decoded: any = jwt_decode(token);
    if (!decoded)
      return null;
    return decoded;
  }
}

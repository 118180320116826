import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ServiceBase } from 'src/app/_shared/_helpers/form-manager.component';
import { environment } from 'src/environments/environment';
import { Department, DepartmentFilters, DepartmentIncludes } from './department.model';

@Injectable()
export class DepartmentService extends ServiceBase<Department, DepartmentIncludes, DepartmentFilters> {
    url = environment.server + 'api/Departments';

    constructor(
        protected http: HttpClient
    ) {
        super(Department);
    }
}
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from '../../_shared/_helpers/property.helper';
import { State } from './state.model';


export class Company extends FormClass {

    @InputProp()
    companyID: number = 0;

    @InputProp({
        placeholder: 'Nombre de la empresa',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    businessName: string;

    @InputProp({
        placeholder: 'RFC',
        inputType: InputType.TEXT,
        validators: [Validators.required, Validators.minLength(10), Validators.maxLength(13)],
        onChange : (form : UntypedFormGroup, event : string) => {
            var rfcControl : UntypedFormControl = form.controls.rfc as any;

            rfcControl.patchValue((rfcControl.value as string).toUpperCase());

            form.updateValueAndValidity();
        },
        style : 'text-transform: uppercase'
    })
    rfc: string;

    @InputProp({
        placeholder: 'Estado',
        inputType: InputType.CHECKBOX,
        validators: []
    })
    status: boolean;

    @InputProp({
        placeholder: 'Calle',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    street: string;

    @InputProp({
        placeholder: 'Número exterior',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(50)]
    })
    outNumber: string;


    @InputProp({
        placeholder: 'Número interior',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(50)]
    })
    inNumber: string;


    @InputProp({
        placeholder: 'Colonia',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    suburb: string;

    @InputProp({
        placeholder: 'Municipio',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    locality: string;

    @InputProp({
        placeholder: 'Código postal',
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    zipCode: string;

    @InputProp({
        placeholder: 'Estado',
        inputType: InputType.SELECT,
        validators: [Validators.required],
        selectConfig: {
            propName: 'statesList',
            value: 'stateID',
            name: 'name'
        }
    })
    stateID: number;

    @InputProp()
    statesList: State[];

    initNewForm(company: Company) {
        var formGroup = this.initForm(company);
        return formGroup
    }

    initEditForm(company: Company) {
        var formGroup = this.initForm(company);
        formGroup.removeControl('status');
        return formGroup
    }
}

export class CompanyFilters {
    state: boolean;
    rfc: string;
    name: string;
    FilterByWorkSpace : number;
}

export class CompanyIncludes {
    includeState: boolean;
    includeDepartments: boolean;
    includeperiods: boolean;
    includeWorkCenters: boolean;
    includeAreas: boolean;
    includePosts: boolean;
    includeEmployees: boolean;
    includeEmailInfo : boolean;
}
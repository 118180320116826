import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginRequest, RegisterRequest } from '../_models/login.model';
import { Session } from '../_models/session.model';
import { LicenseService } from './license.service';
import { Router } from '@angular/router';

@Injectable()

export class SessionService {

    private session: BehaviorSubject<any> = new BehaviorSubject(null);
    _session: Observable<any> = this.session.asObservable().pipe(delay(0));

    private navbarState: BehaviorSubject<number> = new BehaviorSubject(0);
    _navbarState: Observable<number> = this.navbarState.asObservable().pipe(delay(0));

    private topNavbarState: BehaviorSubject<number> = new BehaviorSubject(0);
    _topNavbarState: Observable<number> = this.topNavbarState.asObservable().pipe(delay(0));

    static readonly SESSION_TAG: string = "8e1abf6e-619c-4152-939f-40d894cccb08";

    url = environment.server + 'api/Users/login';

    apiLogoUrl = environment.server + 'api/Companies/logo';

    logo: string = '';

    constructor(
        private http: HttpClient,
        private licenseService: LicenseService,
        private router: Router
    ) {
        this.logo = localStorage.getItem('LOGO');

        this.loadSession()

        setTimeout(() => {
            var s = this.session.getValue();
            if (s && s.type == 'A') {
                this.licenseService.getLicense()
                    .toPromise()
                    .then((res) => {
                        //if (!res.status)
                        this.licenseService.setLicenseState(res.finishLicenseDate);
                        this.licenseService.setLicense(res);
                    })
                    .catch((error) => {
                        //console.error(error);
                    })
            }
        }, 0);

    }

    logout() {
        this.session.next(null);
        localStorage.removeItem(SessionService.SESSION_TAG);
        localStorage.removeItem(SessionService.SESSION_TAG + '-A');
        //sessionStorage.removeItem(SessionService.SESSION_TAG);
        localStorage.removeItem('LOGO');
        this.setNavState(null)
        this.setTopNavState(null)

    }

    login(token: any, adminCompany? : any) {

        

        var session = this.decodeToken(token);

        var actualSesion = this.getSession();

        if(actualSesion && actualSesion.adminOptions)
        {
            localStorage.setItem(SessionService.SESSION_TAG + '-A', actualSesion.token)
        }else{
            localStorage.removeItem(SessionService.SESSION_TAG + '-A')
        }

        if(adminCompany)
        localStorage.setItem(SessionService.SESSION_TAG + '-A', adminCompany)

        this.session.next(session);

        localStorage.setItem(SessionService.SESSION_TAG, session.token);
        //sessionStorage.setItem(SessionService.SESSION_TAG, session.token);
    }

    getAdminToken()
    {
        return localStorage.getItem(SessionService.SESSION_TAG + '-A');
    }

    preLogin(loginRequest: LoginRequest): Observable<any> {
        return this.http.post(this.url + '/us', loginRequest)
    }

    loginCompany(id: number, token: any): Observable<any> {
        return this.http.post(this.url + '/com', { value: id },
            {
                headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
            })
    }

    associatesLogin(loginRequest: LoginRequest) {
        return this.http.post(this.url + '/Associated', loginRequest)
            .toPromise()
            .then((res: any) => {
                var session = this.decodeToken(res.token);

                this.session.next(session);

                localStorage.setItem(SessionService.SESSION_TAG, session.token);
                //sessionStorage.setItem(SessionService.SESSION_TAG, session.token);

            })
            .catch(err => {
                throw err;
            });

    }

    adminLogin(loginRequest: LoginRequest) {
        return this.http.post(this.url + '/su', loginRequest)
            .toPromise()
            .then((res: any) => {
                var session = this.decodeToken(res.token);

                this.session.next(session);

                localStorage.setItem(SessionService.SESSION_TAG, session.token);
                //sessionStorage.setItem(SessionService.SESSION_TAG, session.token);
            })
            .catch(err => {
                throw new Error();
            });

    }

    getLogo() {
        return this.http.get(this.apiLogoUrl).toPromise().then((res: any) => {
            return res.value;

        }).catch(() => {
            return null;
        })
    }
    register(registerRequest: RegisterRequest) {
        return this.http.post(environment.server + 'api/Users/register', registerRequest)
            .toPromise()
            .then((res: any) => {
                // var session = this.decodeToken(res.token);

                // this.session.next(session);

                // localStorage.setItem(SessionService.SESSION_TAG, session.token);
            })
            .catch(err => {
                throw new Error();
            });

    }

    loadSession() {
        var sessionString = localStorage.getItem(SessionService.SESSION_TAG);
        //var sessionString = sessionStorage.getItem(SessionService.SESSION_TAG);

        if (!sessionString)
            return;
        var session = this.decodeToken(sessionString);
        this.session.next(session);

    }

    getSession(): Session {
        return this.session.getValue();
    }

    setNavState(state) {
        this.navbarState.next(state);
    }

    setTopNavState(state) {
        this.topNavbarState.next(state);
    }

    getNavStates()
    {
        return {
            topNavbarState : this.topNavbarState.getValue(),
            navState : this.navbarState.getValue(),
        }
    }

    decodeToken(token: string) {
        var decoded: any = jwt_decode(token);
        if (!decoded)
            return null;

        var session = new Session();
        session.companyName = decoded.CompanyName;
        session.token = token;
        session.email = decoded.Email
        session.type = decoded.LicenseType
        session.companyID = decoded.CompanyID

        session.associatedID = decoded.AssociatedID
        session.associatedLevel = decoded.AssociatedLevel
        session.associatedName = decoded.AssociatedName

        var value : any = (decoded.AdminOptions) ? (decoded.AdminOptions as string ).toLowerCase() : null;
        session.adminOptions = value
        session.isAdmin = decoded.Admin
        session.claimsCompany = decoded.Company
        session.claimsCatalogs = decoded.Catalogs
        session.claimsQuestionaires = decoded.Questionaires
        session.claimsTraking = decoded.Traking
        
        return session;
    }
    

    decodeAndReturn(token: string) {
        var decoded: any = jwt_decode(token);
        if (!decoded)
            return null;

        return decoded;
    }

    

    checkClaims(type : number){

        /*{
            0: "Crear/Editar/Eliminar",
            1: "Lectura",
            3: "Ninguno"
        },*/

        var session = this.getSession()

        if(session.isAdmin == 'true' || session.adminOptions == 'true' || session.type == 'T' || session.type == 'S')
            return true
        
        var route = this.router.url;

        var currentView = this.router.config.find((r : any) => {
            return route.includes(r.data.view);
        })

        //When the route is '/ or empty
        if(!currentView || route == '/')
            return true

        //Get the view claim
        var value = session[currentView.data.claim]

        //Check claim
        if(type == 1)   //1 == Methods that modifies information
        {
            if(value === '0')
                return true
            else 
                return false
        }else {         //2 == Methods that only generate documents
            if(value == '0' || value == '1')
                return true
            else
                return false
        }
    }
}
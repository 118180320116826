import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ServiceBase } from 'src/app/_shared/_helpers/form-manager.component';
import { environment } from 'src/environments/environment';
import { WorkCenter, WorkCenterFilters, WorkCenterIncludes } from './workcenter.model';

@Injectable()
export class WorkCenterService extends ServiceBase<WorkCenter, WorkCenterIncludes, WorkCenterFilters> {
    url = environment.server + 'api/WorkCenters';

    constructor(
        protected http: HttpClient
    ) {
        super(WorkCenter);
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { delay } from 'rxjs/operators';
@Injectable()
export class LicenseService {

    private licenseCaducated: BehaviorSubject<number> = new BehaviorSubject(-1);

    _licenseCaducated: Observable<number> = this.licenseCaducated.asObservable();


    private licenseUrl: BehaviorSubject<string> = new BehaviorSubject(null);

    _licenseUrl: Observable<string> = this.licenseUrl.asObservable().pipe(delay(0));

    private licenseValues : BehaviorSubject<any> = new BehaviorSubject(null);
    
    _licenseValues: Observable<any> = this.licenseValues.asObservable();

    url = environment.server + 'api/Companies/LicenceValues';

    constructor(
        private http: HttpClient
    ) {
        var url =  localStorage.getItem('payments');
        if (url !== undefined) {
            this.licenseUrl.next(url);
        }
    }

    getLicense(): Observable<any> {
        return this.http.get(this.url);
    }

    getLink(){
        return this.licenseUrl.getValue();
    }

    setLicenseState(state, associatedID?) {

        var days = -1;

        if (typeof state === 'number') {
            days = state;
        }
        else {// es una fecha
            var date = moment(state);
            var currentDate = moment().startOf('day');//solo tomar la fecha, sin horas
            var diff = date.diff(currentDate, 'days');
            days = diff <= 0 ? 0 : diff;

        }

        if (associatedID !== undefined) { //Cuando no se envia
            var url = "assets/documents/evaluathec_renovacion_de_licencia.pdf";

            if (associatedID !== null) {
                this.http
                    .get(environment.server + 'api/Associated/' + associatedID + '/document')
                    .toPromise()
                    .then((r: any) => {
                        this.licenseUrl.next(r.value)
                        localStorage.setItem('payments', r.value)
                    })
                    .catch(err => {
                        this.licenseUrl.next(null)
                        localStorage.removeItem('payments')
                    })
            } else{//Cuando se envia pero no tiene valor (por login)
                this.licenseUrl.next(url);
                localStorage.setItem('payments', url)
            }
        }
        this.licenseCaducated.next(days);
    }

    setLicense(license : any)
    {
        this.licenseValues.next(license);
    }


}
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ServiceBase } from '../../_shared/_helpers/form-manager.component';
import { Company, CompanyFilters, CompanyIncludes } from './company.model';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService extends ServiceBase<Company, CompanyIncludes, CompanyFilters> {
    url = environment.server + 'api/Companies';

    constructor(
        protected http: HttpClient
    ) {
        super(Company);
    }

    getLicenseByID(id : number) : Observable<any>{
        return this.http.get(this.url + '/' + id + '/LicenceValues')
    }

    setLogo(fileName : string) : Observable<any>
    {
        return this.http.put(this.url + '/logo', {value :  fileName})
    }

    getLogo() : Observable<any>
    {
        return this.http.get(this.url + '/logo', {
            headers : {'Content-Type': 'application/json'}
        })
    }

    deleteLogo() : Observable<any>
    {
        return this.http.delete(this.url+ '/logo');
    }
    
    checkEmailConfig() : Observable<any>
    { 
        return this.http.get(this.url + '/activeEmailconfig')
    }

    createCompany(newCompany : any, licenseID : any)
    {
        return this.http.post(this.url, {...newCompany}, {params: {value: licenseID}})
    }   

    getPreventionPolicy(sendEmail : boolean){
        return this.http.get(this.url + '/preventionPolicy', {
            params : {
                email : sendEmail as any
            }
        })
    }

    setPreventionPolicy(fileName : string){
        return this.http.put(this.url + '/preventionPolicy', {
            value: fileName
        })
    }
}
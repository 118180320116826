import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';

export enum InputType {
    TEXT = 'text',
    NUMBER = 'number',
    CHECKBOX = 'checkbox',
    SELECT = 'select',
    SELECTOAUTH = 'selectOAuth',
    MULTIPLE_SELECT = 'multiple-select',
    TEXT_AREA = 'text-area',
    DATE = 'date',
    PASSWORD = 'password',
    FILE = 'file',
    RADIO = 'radio'
}

export class CheckBoxConfig{
    orientation? : string 
}

export class FileConfig {
    uploadLimit : number;

    extraSettings? : string[];

    accept: string;

    type : string;
}

export class SelectConfig {
    propName: string;

    value: string;

    name: string;

    onNew?: any;

    onAllSelectClose? : boolean;
}

export class RadioConfig {
    label: string;

    value: boolean;
}

export class InputPropConfig {
    placeholder: string;

    inputType: InputType;

    validators: ValidatorFn[];

    selectConfig?: SelectConfig;

    fileConfig? : FileConfig;

    checkBoxConfig? : CheckBoxConfig;

    separator?: string;

    onChange?: any;

    style? : string;

    radioOptions? : RadioConfig[];
}



export function InputProp(config?: InputPropConfig) {
    return (target: Object, key: string) => {

        function getter() {
            var val = this['_' + key]

            if (!config) {
                return {
                    value: val,
                    placeholder: null,
                    inputType: null,
                    formControl: null
                };
            }
            var control: UntypedFormControl = new UntypedFormControl(val, config.validators);
            control['placeholder'] = config.placeholder;
            control['controlType'] = config.inputType
            control['separator'] = config.separator;
            control['onChange'] = config.onChange;
            control['style'] = config.style;

            if (config.selectConfig) {
                control['selectConfig'] = {
                    data: this['_' + config.selectConfig.propName],
                    value: config.selectConfig.value,
                    name: config.selectConfig.name,
                    onNew: config.selectConfig.onNew,
                    onAllSelectClose : config.selectConfig.onAllSelectClose
                }
            }
            
            if (config.fileConfig) {
                control['fileConfig'] = {
                    extraSettings: config.fileConfig.extraSettings,
                    uploadLimit: config.fileConfig.uploadLimit,
                    accept: config.fileConfig.accept,
                    type: config.fileConfig.type
                }
            }

            if (config.checkBoxConfig) {
                control['checkBoxConfig'] = {
                    orientation: config.checkBoxConfig.orientation
                }
            }

            if(config.inputType == InputType.PASSWORD)
            {
                control['hide'] = false;
            }

            // Nueva sección para radioOptions
            if (config.inputType === InputType.RADIO && config.radioOptions) {
                control['radioOptions'] = config.radioOptions;
            }

            return {
                value: val,
                placeholder: config.placeholder,
                inputType: config.inputType,
                formControl: control
            };
        }

        function setter(next) {
            this['_' + key] = next;
        }

        Object.defineProperty(target, key, {
            get: getter,
            set: setter,
            enumerable: true,
            configurable: true,
        });
    }
}
export class FormClass {

    protected initForm(item) {
        var formGroup = new UntypedFormGroup({});

        if (!item) {
            return formGroup;
        }

        formGroup['extraProperties'] = {};
        for (var k in item) {
            if (k.startsWith('_'))
                continue;

            if (item[k] && item[k]['formControl']) {
                formGroup.addControl(k, item[k]['formControl']);
            } else {
                // formGroup[k] = item[k];
                formGroup['extraProperties'][k] = item[k];
            }
        }
        return formGroup;
    }

}
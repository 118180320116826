import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TableModule } from 'src/app/_shared/table/table.module';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FormDialogModule } from 'src/app/_shared/_components/form-dialog/form-dialog.module';
import { QuestionnaireComponent } from './questionnaire.component';
import { QuestionnaireService } from 'src/app/_shared/_services/questionnaire.service';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PeriodService } from 'src/app/settings/period/period.service';
import { WorkCenterService } from 'src/app/settings/workcenter/workcenter.service';
import { DepartmentService } from 'src/app/settings/department/department.service';
import { ReactiveFormsModule } from '@angular/forms';
import { SurveyService } from '../survey/survey.service';
import { AreaService } from 'src/app/settings/area/area.service';
import { CompanyService } from 'src/app/settings/company/company.service';
import { UserCompanyService } from 'src/app/user-company/user-company.service';
import { PostService } from 'src/app/settings/post/posts.service';

const routes: Routes = [
    { path: '', component: QuestionnaireComponent }
];

@NgModule({
    declarations: [
        QuestionnaireComponent
    ],
    imports: [
        CommonModule, 
        RouterModule.forChild(routes),
        TableModule,
        MatSnackBarModule,
        MatDialogModule,
        FormDialogModule,
        MatCardModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    exports: [RouterModule],
    providers : [
        QuestionnaireService,
        PeriodService,
        WorkCenterService,
        DepartmentService,
        AreaService,
        SurveyService,
        CompanyService,
        UserCompanyService,
        PostService
    ]
})
export class QuestionnaireRoutingModule { }

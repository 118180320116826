import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ServiceBase } from 'src/app/_shared/_helpers/form-manager.component';
import { environment } from 'src/environments/environment';
import { Area, AreaFilters, AreaIncludes } from './area.model';

@Injectable()
export class AreaService extends ServiceBase<Area, AreaIncludes, AreaFilters> {
    url = environment.server + 'api/Areas';

    constructor(
        protected http: HttpClient
    ) {
        super(Area);
    }
}
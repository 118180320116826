<div class="loader-wrapper" [ngClass]="{'active':loaderState | async }">
    <div class="loader-screen"></div>

    <div class="loader-icon">
        <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- <div><span style="color: rgb(173, 173, 173);">CARGANDO...</span></div> -->
        <div><span style="color: white;">CARGANDO...</span></div>
    </div>

</div>
import { Injectable } from '@angular/core';
import { ServiceBase } from '../_shared/_helpers/form-manager.component';
import { UserCompany, UserCompanyIncludes, UserCompanyFilters } from './user-company.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PagingParams } from '../_shared/_models/paging.model';
import { OrderBy } from '../_shared/_models/table.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class UserCompanyService {
    
    constructor(
        protected http: HttpClient
    ) { 
    }

    getAll(paging?: PagingParams, filters?: UserCompanyFilters, includes?: UserCompanyIncludes, orderBy? : OrderBy[], token?: string) {
        
        return this.http.get(environment.server + 'api/Users', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new UserCompany, i));
                return x;
            })
        )
    }
    
    getAllLicenses(paging?: PagingParams, filters?: UserCompanyFilters, includes?: UserCompanyIncludes, orderBy? : OrderBy[], token?: string) {
        
        return this.http.get(environment.server + 'api/Companies/Licenses', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new UserCompany, i));
                return x;
            })
        )
    }


    /*
    *   Licencias disponibles para un usuario titular
    */
    getAllAvailableLicenses(paging?: PagingParams, filters?: any, includes?: any, orderBy? : OrderBy[],) : Observable<any>
    {
        return this.http.get(environment.server + 'api/AviableLicenses', {
         params: {
            ...paging as any,
            ...filters as any,
            ...includes as any,
            orderBy: (orderBy) ? JSON.stringify(orderBy) : null
        }});
    }

    
    /**
     * Todos los asociados existentes
     */
    getAllAssociates(paging?: PagingParams, filters?: any, includes?: UserCompanyIncludes, orderBy? : OrderBy[]) : Observable<any>
    {
        return this.http.get(environment.server + 'api/Associated', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new UserCompany, i));
                return x;
            })
        )
    }

    /**
     * Solo asociados autorizados
     * @param paging 
     * @param filters 
     * @param includes 
     * @param orderBy 
     */
    getOnlyAuthorizedAssociates(paging?: PagingParams, filters?: any, includes?: UserCompanyIncludes, orderBy? : OrderBy[]) : Observable<any>
    {
        return this.http.get(environment.server + 'api/Associated/allAthorize', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new UserCompany, i));
                return x;
            })
        )
    }

    /**
     * Solo asociados autorizados
     * @param paging 
     * @param filters 
     * @param includes 
     * @param orderBy 
     */
    getOnlyMasterAssociates(paging?: PagingParams, filters?: any, includes?: UserCompanyIncludes, orderBy? : OrderBy[]) : Observable<any>
    {
        return this.http.get(environment.server + 'api/Associated/allMasters', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new UserCompany, i));
                return x;
            })
        )
    }

    /**
     * Asociados autorizados de un master
     */
    getMasterAssociates(paging?: PagingParams, filters?: UserCompanyFilters, includes?: UserCompanyIncludes, orderBy? : OrderBy[])
    {
        return this.http.get(environment.server + 'api/Associated/master', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new UserCompany, i));
                return x;
            })
        )
    }

    /**
     * Asignar asociado master a un asociado autorizado
     */
    assignMaster(id: number, masterId: number){
        return this.http.put(environment.server + 'api/Associated/' + id + "/taketo/" + masterId, {})
    }

    /**
     * Cambiar el asociado/distribuidor de una empresa
     */
    changeAssociates(info : any) : Observable<any>
    {
        return this.http.post(environment.server + 'api/Companies/associated/change', { ...info});
    }

    /**
     * Activación de cuenta 
     */
    confirmCreate(token : string, info) : Observable<any>{
        return this.http.post(environment.server + 'api/Users/create/activation', { ...info}, 
        {
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})   
        });
    }
    /**
     * Pre-registro de una empresa (información básica); despues se usa confirmCreate para activar la cuenta y asignar contraseña
     */
    create(userInfo, companyInfo) {
        return this.http.post(environment.server + 'api/Users/create', {userInfo, companyInfo});
    }



    /**
     * Activación de cuenta de asociado
     */
    confirmCreateAssociates(token : string, info) : Observable<any>{
        return this.http.post(environment.server + 'api/Users/associated/confirm', { ...info}, 
        {
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})   
        });
    }

    /**
     * Pre-registro de una cuenta de asociado
     */
    createAssociates(userInfo) {
        return this.http.post(environment.server + 'api/Users/associateds/register', userInfo);
    }

    
    /**
     * Actualización de los valores de la licencia de una compañia
     */
    update(id: number, item: UserCompany) {
        return this.http.put(environment.server + 'api/Companies/' + id + '/licensevalues', item);
    }

    /**
     * Actualización de los valores de una cuenta de asociado
     */
    updateAssociated(id: number, item: any){
        return this.http.put(environment.server + 'api/Associated/' + id , item);
    }

    /**
     * Inhabilitar asociado
     */
    disableAssociated(id: number) : Observable<any>
    {
        return this.http.put(environment.server + 'api/Associated/' + id + '/look', {id : id})
    }

    /**
     * Habilitar asociado
     */
    enableAssociated(id: number) : Observable<any>
    {
        return this.http.put(environment.server + 'api/Associated/' + id + '/unlook', {id : id})
    }


    /**
     * Cambio de un asociado autorizado a asociado master
     */
    associateToMaster(id: number){
        return this.http.put(environment.server + 'api/Associated/' + id + '/upgrade', {id : id});
    }
    
    setPassword(userName: string, password : string) : Observable<any>
    {   
        return this.http.post(environment.server + 'api/Users/password/set', {
            userName : userName,
            password : password
        });
    }

    requestPasswordChange(userName: string) : Observable<any>
    {   
        return this.http.post(environment.server + 'api/Users/password/forgotten', {
            userName : userName,
        });
    }

    getLogs(paging?: PagingParams, filters?: any, includes?: any) : Observable<any> {
        
        return this.http.get(environment.server + 'api/Companies/logs', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
            },
        });
    }

    getUsers(paging?: PagingParams) : Observable<any> {
        
        return this.http.get(environment.server + 'api/Companies/users', {
            params: {
                ...paging as any,
            },
        });
    }

    getAllUsers(paging?: PagingParams, filters?: any, orderBy? : OrderBy[], includes?: any) : Observable<any> {
        
        return this.http.get(environment.server + 'api/Users', {
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            },
        });
    }

    createUser(newUser : any){
        return this.http.post(environment.server + 'api/Users/ws/add', newUser)
    }

    updateUser(updatedUser : any)
    {
        return this.http.post(environment.server + 'api/Users/company/update', updatedUser)
    }

    updateOnlyUser(updatedUser : any)
    {
        return this.http.put(environment.server + 'api/Users', updatedUser)
    }

    confirmCreateUser(token : string, info){

        return this.http.post(environment.server + 'api/Users/company/confirm', { ...info}, 
        {
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token})   
        });
    }

    

    updateRole(userID : any, roleID : any, isAdministrator : any, isSupervisor : any){
        
        return this.http.post(environment.server + 'api/Users/ws/role', 
        { 
            roleID : roleID,
            userID: userID,
            isAdministrator : (!isAdministrator) ? false : isAdministrator,
            isSupervisor : (!isSupervisor) ? false : isSupervisor
        })
    }
    

    updateCompanies(userID : any, companies : any[]){
        return this.http.post(environment.server + 'api/Users/ws/companies', { userID: userID, companies : companies})
    }

    deleteUser(id : any)
    {
        return this.http.post(environment.server + 'api/Users/ws/delete',  { value : id})
    }


    addLicense(newLicence : any)
    {
        return this.http.post(environment.server + 'api/AviableLicenses', {...newLicence})
    }

    updateLicense(id: number, newLicence : any)
    {
        return this.http.put(environment.server + 'api/AviableLicenses/' + id, {...newLicence})
    }

    deleteLicense(id: number)
    {
        return this.http.delete(environment.server + 'api/AviableLicenses/' + id)
    }

    changeTitulaUser(workSpaceID : number, email : string){
        return this.http.put(environment.server + 'api/Users/ws/' + workSpaceID + '/principal', {value : email});
    }

    changeApplicator(updateApplicator : any) : Observable<any> {
        return this.http.put(environment.server + 'api/Companies/aplicator', updateApplicator);
    }

    getApplicator() : Observable<any> {
        return this.http.get(environment.server + 'api/Companies/aplicator');
    }
}

    

import { ErrorHandler, Injectable, Injector } from "@angular/core";

import * as Sentry from "@sentry/browser";
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

Sentry.init({
  dsn: "https://ca23f8f38d0c4142a7035055dcbbe76e@o155400.ingest.sentry.io/5246699",
  release : environment.version
});


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() {}
  
  handleError(error) {
    if(environment.production)
    {
      const eventId = Sentry.captureException(error.originalError || error);
      //Sentry.showReportDialog({ eventId });
    }else
    {
      console.error(error);
      //Claim check
    }
  }
}
import { UntypedFormControl, UntypedFormGroup, Validators, Form, FormControl } from '@angular/forms';
import { State } from '../settings/company/state.model';
import { FormClass, InputProp, InputType } from '../_shared/_helpers/property.helper';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormDialogComponent } from '../_shared/_components/form-dialog/form-dialog.component';

import * as moment from 'moment';


export class UserCompany extends FormClass {
    @InputProp()
    companyID: number;

    @InputProp({
        placeholder: 'Tipo de Licencia',
        inputType: InputType.SELECT,
        validators: [],
        selectConfig: {
            propName: 'licenseTypeList',
            value: 'value',
            name: 'name'
        }
    })
    licenseType: number;

    @InputProp()
    licenseTypeList: any[] = [
        { value: 0, name: 'Prueba' },
        { value: 1, name: 'Asociado' },
        { value: 2, name: 'Cliente' }];

    @InputProp({
        placeholder: 'Finalización de licencia',
        inputType: InputType.DATE,
        validators: [Validators.required]
    })
    expirationDate: string;

    @InputProp({
        placeholder: 'Tamaño de licencia',
        inputType: InputType.SELECT,
        validators: [],
        selectConfig: {
            propName: 'sizeList',
            value: 'value',
            name: 'name'
        },
        onChange: async (form: UntypedFormGroup, event, dialog?: MatDialog, data?: any[]) => {
            if (!form || !data)
                return;

            var sizes = [15, 50, 100, 500, 2500];

            var size: UntypedFormControl = form.controls.aviableEmployees as any;

            size.setValue(sizes[parseInt(event.value)])

            form.updateValueAndValidity();
        }
    })
    sizeLicense: string;

    @InputProp()
    sizeList: any[] = [
        { value: 0, name: 'Micro' },
        { value: 1, name: 'Pequeña' },
        { value: 2, name: 'Mediana' },
        { value: 3, name: 'Grande' },
        { value: 4, name: 'Ilimitada' }
    ];

    @InputProp({
        placeholder: 'Usuarios disponibles',
        inputType: InputType.NUMBER,
        validators: [Validators.required],
        onChange: async (form: UntypedFormGroup, event, dialog?: MatDialog) => {

            var input : UntypedFormControl = form.controls.aviableEmployees as any;

            if (!form || isNaN(input.value))
                return;

            var aviableEmployees = parseInt(input.value);
            var opt;

            var sizeOpts: UntypedFormControl = form.controls.sizeLicense as any;

            if (aviableEmployees <= 15)
                opt = 0;
            if (aviableEmployees > 15 && aviableEmployees <= 50)
                opt = 1;
            if (aviableEmployees > 50 && aviableEmployees <= 100)
                opt = 2;
            if (aviableEmployees > 100 && aviableEmployees <= 500)
                opt = 3;
            if (aviableEmployees > 500)
                opt = 4;
            
            sizeOpts.patchValue(opt);

            form.updateValueAndValidity();
        }
    })
    aviableEmployees: string;

    

    @InputProp({
        placeholder: 'Socio asignado',
        inputType: InputType.SELECT,
        validators: [],
        selectConfig: {
            propName: 'associatedList',
            value: 'value',
            name: 'name'
        }
    })
    associatedID: number;

    @InputProp()
    associatedList: any[];

    



    initNewForm(item: FormClass) {
        var formGroup = this.initForm(item);

        return formGroup;
    }

    initNewExtraForm(item: FormClass) {
        var formGroup = this.initForm(item);

        formGroup.removeControl('associatedID')

        return formGroup;
    }

    initEditForm(item: any) {
        var formGroup = this.initForm(item);

        formGroup.get('aviableEmployees').setValue(item.aviableEmployees.value)
        formGroup.get('expirationDate').setValue(item.expirationDate.value)
        formGroup.get('licenseType').setValue(item.licenseType.value)
        
        if(item.associatedID.value)
            formGroup.get('associatedID').setValue(item.associatedID.value)
        else
            formGroup.get('associatedID').setValue(0)

        var expirationDate = moment(item.expirationDate.value)

        /* if(item.licenseType.value === 2)
        {
            expirationDate.add(1, 'year');
            formGroup.get('expirationDate').setValue(expirationDate.toDate());
        }    */

        return formGroup;
    }
}

export class NewUser extends FormClass {

    @InputProp({
        placeholder: "Nombre(s)",
        inputType: InputType.TEXT,
        validators: [],

        separator: 'Datos del Usuario'
    })
    name: string;

    @InputProp({
        placeholder: "Apellido Paterno",
        inputType: InputType.TEXT,
        validators: []
    })
    lastName: string;

    @InputProp({
        placeholder: "Apellido Materno",
        inputType: InputType.TEXT,
        validators: []
    })
    motherLastName: string;

    @InputProp({
        placeholder: "Número Telefónico",
        inputType: InputType.TEXT,
        validators: []
    })
    phoneNumber: string;

    @InputProp({
        placeholder: "Correo Electrónico",
        inputType: InputType.TEXT,
        validators: [Validators.email, Validators.required]
    })
    email: string;

    @InputProp({
        placeholder: "Nombre Completo o Razón Social",
        inputType: InputType.TEXT,
        validators: [Validators.required],
        separator: 'Datos de la Empresa'
    })
    businessName: string

    @InputProp({
        placeholder: "RFC",
        inputType: InputType.TEXT,
        validators: [Validators.required,Validators.minLength(10), Validators.maxLength(13)],
        onChange : (form : UntypedFormGroup, event : string) => {
            var rfcControl : UntypedFormControl = form.controls.rfc as any;

            rfcControl.patchValue((rfcControl.value as string).toUpperCase());

            form.updateValueAndValidity();
        },
        style : 'text-transform: uppercase'
    })
    rfc: string;

    @InputProp({
        placeholder: "Estado",
        inputType: InputType.SELECT,
        validators: [],
        selectConfig: {
            propName: "stateList",
            value: 'stateID',
            name: 'name'
        }
    })
    stateID: number;

    @InputProp()
    stateList: State[]

    @InputProp({
        placeholder: "Municipio",
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    locality: string;

    @InputProp({
        placeholder: "Código Postal",
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    zipCode: string;

    @InputProp({
        placeholder: "Colonia",
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    suburb: string;

    @InputProp({
        placeholder: "Calle",
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(255)]
    })
    street: string;

    @InputProp({
        placeholder: "Número Exterior",
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(50)]
    })
    outNumber: string;

    @InputProp({
        placeholder: "Número Interior",
        inputType: InputType.TEXT,
        validators: [Validators.maxLength(50)]
    })
    inNumber: string;

    

    @InputProp({
        placeholder: "Estatus",
        inputType: InputType.CHECKBOX,
        validators: [Validators.required]
    })
    status: boolean;


    initNewForm(newUser: NewUser) {
        var formGroup = this.initForm(newUser);

        return formGroup
    }

}

export class UserCompanyFilters {
    FilterByConfirmed?: boolean;
    FilterByUserName?: string;
    FilterByName?: string;
    FilterByLicenseStatus?: boolean;
    FilterByRFC?: string;
    FilterByStateID?: number;
    FilterbyType?: number;
    FilterByUser?: string;
    FilterByContactEmail?: string;
    FilterBySerie?: string;
    FilterByCompanyID? : number;
    FilterByAviableEmployees? : number
    FilterByAssociated? : number;
    FilterByLicenseType? : number;
    FilterByStatus? : boolean;
}

export class UserCompanyIncludes {

}

export class EmailConfiguration extends FormClass {
    @InputProp()
    emailConfigID?: number;

    @InputProp()
    useSSL: boolean;

    @InputProp()
    emailPoolID: number;

    @InputProp()
    status: boolean;

    @InputProp({
        placeholder: 'Autentificación',
        inputType: InputType.RADIO,
        validators: [Validators.required],
        radioOptions: [{
            value: false,
            label: 'SMTP'
        }, {
            value: true,
            label: 'OAuth'
        }]
    })
    isOauth: boolean = false; //smtp default
    

    @InputProp({
        placeholder: 'Configuración',
        inputType: InputType.SELECT,
        validators: [Validators.required],
        selectConfig:
        {
            propName: 'hostTypeList',
            value: 'value',
            name: 'name'
        },
        onChange: async (form: UntypedFormGroup, event, dialog?: MatDialog) => {
            if (!form)
                return;

            var data = {
                name: "Nota",
                message: 'Para el correcto funciomiento es indispensable habilitar el acceso a aplicaciones de terceros o apps \"menos\" seguras en tu cuenta de correo electronico.',
                extraMessages: [
                    {
                        value: "Conoce como realizar esta accion en los siguientes links:"
                    },
                    {
                        value: "https://support.google.com/accounts/answer/6010255?hl=es-419",
                        placeholder: "Gmail",
                        isUrl: true
                    },
                    {
                        value: "https://support.microsoft.com/es-mx/help/12409/microsoft-account-app-passwords-and-two-step-verification",
                        placeholder: "Outlook",
                        isUrl: true
                    }
                ],
                noCancel: true
            }

            await dialog.open(FormDialogComponent, { data: data, width: "700px" }).afterClosed().toPromise()


            var host: UntypedFormControl = form.controls.host as any;
            var port: UntypedFormControl = form.controls.port as any;
            var capacity: UntypedFormControl = form.controls.capacity as any;

                if (!form.get('isOauth').value) {
                    switch (event.value) {
                        case '0':
                            host.setValue('');
                            port.setValue('');
                            capacity.setValue('');
                            break;
                        case '1':
                            host.setValue('smtp.gmail.com');
                            port.setValue('465');
                            capacity.setValue(10000);
                            break;
                        case '2':
                            host.setValue('smtp-mail.outlook.com');
                            port.setValue('587');
                            capacity.setValue(10000);
                            break;
                        case '3':
                            host.setValue('smtp.office365.com');
                            port.setValue('587');
                            capacity.setValue(10000);
                            break;
                        case '4':
                            host.setValue('smtp.mail.yahoo.com');
                            port.setValue('587');
                            capacity.setValue(10000);
                            break;
                    }
                } 
                else {
                }

            form.updateValueAndValidity();
        }
    })
    hostType: string = '0';


    @InputProp({
        placeholder: 'Configuración',
        inputType: InputType.SELECTOAUTH,
        validators: [Validators.required],
        selectConfig:
        {
            propName: 'oAuthHostTypeList',
            value: 'value',
            name: 'name'
        },
        onChange: async (form: UntypedFormGroup, event, dialog?: MatDialog) => {
            if (!form)
                return;

            var data = {};
            console.log(form,form['hostTypeOAuth'])
            if(form.value['hostTypeOAuth']==1) {
                data = {
                    name: "Gmail",
                    message: 'Para configurar correctamente tu emisión de correos vía Gmail y autentificación OAUTH, te compartimos el manual paso a paso del proceso',
                    group: null,
                    template: null,
                    noCancel: true,
                    url: 'https://evaluathec.com/ProcedimientoRegistroGmail.pdf',
                    showManualButton: true 
                }
                await dialog.open(FormDialogComponent, { data: data, width: "700px" }).afterClosed().toPromise()
            }
            else if(form.value['hostTypeOAuth']==2) {
                data = {
                    name: "Microsoft",
                    message: 'Para configurar correctamente tu emisión de correos vía Microsoft y autentificación OAUTH, te compartimos el manual paso a paso del proceso',
                    group: null,
                    template: null,
                    noCancel: true,
                    url: 'https://evaluathec.com/ProcedimientoRegistroMicrosoft.pdf',
                    showManualButton: true 
                }    
                await dialog.open(FormDialogComponent, { data: data, width: "700px" }).afterClosed().toPromise()
            }
            console.log(data)



            var host: UntypedFormControl = form.controls.host as any;
            var port: UntypedFormControl = form.controls.port as any;
            var capacity: UntypedFormControl = form.controls.capacity as any;

                if (!form.get('isOauth').value) {
                    switch (event.value) {
                        case '0':
                            host.setValue('');
                            port.setValue('');
                            capacity.setValue('');
                            break;
                        case '1':
                            host.setValue('smtp.gmail.com');
                            port.setValue('465');
                            capacity.setValue(10000);
                            break;
                        case '2':
                            host.setValue('smtp-mail.outlook.com');
                            port.setValue('587');
                            capacity.setValue(10000);
                            break;
                        case '3':
                            host.setValue('smtp.office365.com');
                            port.setValue('587');
                            capacity.setValue(10000);
                            break;
                        case '4':
                            host.setValue('smtp.mail.yahoo.com');
                            port.setValue('587');
                            capacity.setValue(10000);
                            break;
                    }
                } 
                else {
                }

            form.updateValueAndValidity();
        }
    })
    hostTypeOAuth: string = '0';

    @InputProp()
    fullHostTypeList: any[] = [{
        value: '0',
        name: 'Personalizada'
    }, {
        value: '1',
        name: 'Gmail'
    }
    , {
        value: '2',
        name: 'MSN'
    }, {
        value: '3',
        name: 'Microsoft 365'
    }, {
        value: '4',
        name: 'Yahoo!'
    }
    ]

    @InputProp()
    hostTypeList: any[] = [{
        value: '0',
        name: 'Personalizada'
    }, {
        value: '1',
        name: 'Gmail'
    }
    , {
        value: '2',
        name: 'MSN'
    }, {
        value: '3',
        name: 'Microsoft 365'
    }, {
        value: '4',
        name: 'Yahoo!'
    }
    ]

    @InputProp()
    oAuthHostTypeList: any[] = [{
        value: '0',
        name: 'Personalizada'
    }, {
        value: '1',
        name: 'Gmail'
    }/*, {
        value: '2',
        name: 'Microsoft'
    }*/
    ]
    
    private oAuthHostTypes = [
        { value: '0', name: 'Personalizada' },
        { value: '1', name: 'Gmail' },
        { value: '2', name: 'Microsoft' }
      ];

    private allHostTypes = [
        { value: '0', name: 'Personalizada' },
        { value: '1', name: 'Gmail' },
        { value: '2', name: 'MSN' },
        { value: '3', name: 'Microsoft 365' },
        { value: '4', name: 'Yahoo!' }
      ];

    configurationType: string = 'smtp';



    @InputProp({
        placeholder: 'Host',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    host: string;

    @InputProp({
        placeholder: 'Puerto',
        inputType: InputType.NUMBER,
        validators: [Validators.required]
    })
    port: number;

    @InputProp({
        placeholder: 'Nombre de Usuario',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    userName: string;

    @InputProp({
        placeholder: 'Capacidad',
        inputType: InputType.NUMBER,
        validators: [Validators.required]
    })
    capacity: number;

    @InputProp({
        placeholder: 'Email',
        inputType: InputType.TEXT,
        validators: [Validators.required, Validators.email]
    })
    email: string;

    @InputProp({
        placeholder: 'Contraseña',
        inputType: InputType.PASSWORD,
        validators: [Validators.required]
    })
    password: string;


    @InputProp({
        placeholder: 'Client ID',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    clientId: string;

    @InputProp({
        placeholder: 'Project Tenant ID',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    projectTenantId: string;

    @InputProp({
        placeholder: 'Client Secret',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    clientSecret: string;


    initNewForm(item: EmailConfiguration): UntypedFormGroup {
        const formGroup = super.initForm(item);
        //formGroup.addControl('configurationType', new FormControl(item.configurationType || 'smtp'));
        this.hostType = '0';
        this.setupConfigurationTypeHandling(formGroup);
        
        // Initialize the form based on the initial configuration type
        if (!formGroup.get('isOauth').value) {
            this.hostTypeOAuth = '0';
            this.initUpdateFormBasedOnConfigurationType(formGroup, item.configurationType || 'smtp');
        } else {
            this.hostTypeOAuth = '1';
            this.initUpdateFormBasedOnConfigurationType(formGroup, 'oauth');
        }
        return formGroup;
    }

    initEditForm(item: EmailConfiguration): UntypedFormGroup {
        return this.initNewForm(item);
    }

    private setupConfigurationTypeHandling(formGroup: UntypedFormGroup): void {
        formGroup.get('isOauth').valueChanges.subscribe((isSimple: boolean) => {
            if (!isSimple) {
                // formGroup.patchValue({ isAdvanced: false }, { emitEvent: false });
                this.updateFormBasedOnConfigurationType(formGroup, 'smtp');
            } 
            else {
                // formGroup.patchValue({ isSimple: true }, { emitEvent: false });
            }
        });

        formGroup.get('isOauth').valueChanges.subscribe((isAdvanced: boolean) => {
            if (isAdvanced) {
                // formGroup.patchValue({ isSimple: false }, { emitEvent: false });
                this.updateFormBasedOnConfigurationType(formGroup, 'oauth');
            }
            else {
                // formGroup.patchValue({ isAdvanced: true }, { emitEvent: false });
            }
        });
    }

    private initUpdateFormBasedOnConfigurationType(formGroup: UntypedFormGroup, configurationType: string): void {
        formGroup.patchValue({
            configurationType: configurationType
        }, { emitEvent: false });

        if (configurationType === 'oauth') {
            this.hostTypeList = [...this.oAuthHostTypes];
            this.enableAdditionalFields(formGroup);
            formGroup.controls.host.setValue('');
            formGroup.controls.port.setValue('');
        } else {
            this.hostTypeList = [...this.allHostTypes];
            this.disableAdditionalFields(formGroup);
            switch ((formGroup.get('hostType').value)) {
                case '0': 
                    break;
                case '1':
                    formGroup.controls.host.setValue('smtp.gmail.com');
                    formGroup.controls. port.setValue('465');
                    formGroup.controls.capacity.setValue(10000);
                    break;
                case '2':
                    formGroup.controls.host.setValue('smtp-mail.outlook.com');
                    formGroup.controls.port.setValue('587');
                    formGroup.controls.capacity.setValue(10000);
                    break;
                case '3':
                    formGroup.controls.host.setValue('smtp.office365.com');
                    formGroup.controls.port.setValue('587');
                    formGroup.controls.capacity.setValue(10000);
                    break;
                case '4':
                    formGroup.controls.host.setValue('smtp.mail.yahoo.com');
                    formGroup.controls.port.setValue('587');
                    formGroup.controls.capacity.setValue(10000);
                    break;
            }
        }

        formGroup.updateValueAndValidity();
    }

    private updateFormBasedOnConfigurationType(formGroup: UntypedFormGroup, configurationType: string): void {
        formGroup.patchValue({
            configurationType: configurationType
        }, { emitEvent: false });

        if (configurationType === 'oauth') {
            this.hostTypeList = [...this.oAuthHostTypes];
            this.enableAdditionalFields(formGroup);
            formGroup.controls.host.setValue('');
            formGroup.controls.port.setValue('');
            //formGroup.controls.capacity.setValue('');
        } else {
            this.hostTypeList = [...this.allHostTypes];
            this.disableAdditionalFields(formGroup);
            switch ((formGroup.get('hostType').value)) {
                case '0':
                    formGroup.controls.host.setValue('');
                    formGroup.controls.port.setValue('');
                    formGroup.controls.capacity.setValue('');
                    break;
                case '1':
                    formGroup.controls.host.setValue('smtp.gmail.com');
                    formGroup.controls. port.setValue('465');
                    formGroup.controls.capacity.setValue(10000);
                    break;
                case '2':
                    formGroup.controls.host.setValue('smtp-mail.outlook.com');
                    formGroup.controls.port.setValue('587');
                    formGroup.controls.capacity.setValue(10000);
                    break;
                case '3':
                    formGroup.controls.host.setValue('smtp.office365.com');
                    formGroup.controls.port.setValue('587');
                    formGroup.controls.capacity.setValue(10000);
                    break;
                case '4':
                    formGroup.controls.host.setValue('smtp.mail.yahoo.com');
                    formGroup.controls.port.setValue('587');
                    formGroup.controls.capacity.setValue(10000);
                    break;
            }
        }

        formGroup.updateValueAndValidity();
    }

    private enableAdditionalFields(form: UntypedFormGroup): void {

        // Oculta campos que no se requieren para OAuth y los deshabilita
        if (form.get('hostType')) {
            form.get('hostType').disable();
            form.get('hostType')['hidden'] = true; // Oculta el campo
        }
        if (form.get('host')) {
            form.get('host').disable(); // Mantiene el disable
            form.get('host')['hidden'] = true; // Oculta el campo
        }
        if (form.get('port')) {
            form.get('port').disable();
            form.get('port')['hidden'] = true;
        }
        if (form.get('userName')) {
            form.get('userName').disable();
            form.get('userName')['hidden'] = true;
        }
        if (form.get('password')) {
            form.get('password').disable();
            form.get('password')['hidden'] = true;
        }
    
        // Muestra campos adicionales para OAuth y los habilita
        if (form.get('hostTypeOAuth')) {
            form.get('hostTypeOAuth').enable();
            form.get('hostTypeOAuth')['hidden'] = false; // Muestra el campo
        }
        if (!form.get('clientId')) {
            //form.addControl('clientId', new FormControl('', Validators.required));
        } else {
            form.get('clientId').enable(); // Mantiene el enable
            form.get('clientId')['hidden'] = false; // Muestra el campo
        }
        if (!form.get('projectTenantId')) {
        } else {
            form.get('projectTenantId').enable();
            form.get('projectTenantId')['hidden'] = false;
        }
        if (!form.get('clientSecret')) {
        } else {
            form.get('clientSecret').enable();
            form.get('clientSecret')['hidden'] = false;
        }
    }
    
    private disableAdditionalFields(form: UntypedFormGroup): void {

        // Oculta campos adicionales para OAuth y los deshabilita     
        if (form.get('hostTypeOAuth')) {
            form.get('hostTypeOAuth').disable();
            form.get('hostTypeOAuth')['hidden'] = true; // Oculta el campo
        }
        if (form.get('clientId')) {
            form.get('clientId').disable(); // Mantiene el disable
            form.get('clientId')['hidden'] = true; // Oculta el campo
        }
        if (form.get('projectTenantId')) {
            form.get('projectTenantId').disable();
            form.get('projectTenantId')['hidden'] = true;
        }
        if (form.get('clientSecret')) {
            form.get('clientSecret').disable();
            form.get('clientSecret')['hidden'] = true;
        }
    
        // Muestra campos que se requieren para SMTP y los habilita
        if (form.get('hostType')) {
            form.get('hostType').enable();
            form.get('hostType')['hidden'] = false; // Muestra el campo
        }
        if (!form.get('host')) {
        } else {
            form.get('host').enable(); // Mantiene el enable
            form.get('host')['hidden'] = false; // Muestra el campo
        }
        if (!form.get('port')) {
        } else {
            form.get('port').enable();
            form.get('port')['hidden'] = false;
        }
        if (!form.get('userName')) {
        } else {
            form.get('userName').enable();
            form.get('userName')['hidden'] = false;
        }
        if (!form.get('password')) {
        } else {
            form.get('password').enable();
            form.get('password')['hidden'] = false;
        }
    }
    
    
    
}

export class NewLicense extends FormClass{
    @InputProp()
    companyID: number;

    @InputProp({
        placeholder: 'Duración (días)',
        inputType: InputType.NUMBER,
        validators: [Validators.required]
    })
    durationDays: number;

    @InputProp({
        placeholder: 'Usuarios disponibles',
        inputType: InputType.NUMBER,
        validators: [Validators.required],
        onChange: async (form: UntypedFormGroup, event, dialog?: MatDialog) => {

            var input : UntypedFormControl = form.controls.employeAmount as any;

            if (!form || isNaN(input.value))
                return;

            var employeAmount = parseInt(input.value);
            var opt;

            var sizeOpts: UntypedFormControl = form.controls.sizeLicense as any;

            if (employeAmount <= 15)
                opt = 0;
            if (employeAmount > 15 && employeAmount <= 50)
                opt = 1;
            if (employeAmount > 50 && employeAmount <= 100)
                opt = 2;
            if (employeAmount > 100 && employeAmount <= 500)
                opt = 3;
            if (employeAmount > 500)
                opt = 4;
            
            sizeOpts.patchValue(opt);

            form.updateValueAndValidity();
        }
    })
    employeAmount: string;

    @InputProp({
        placeholder: 'Tamaño de licencia',
        inputType: InputType.SELECT,
        validators: [],
        selectConfig: {
            propName: 'sizeList',
            value: 'value',
            name: 'name'
        },
        onChange: async (form: UntypedFormGroup, event, dialog?: MatDialog, data?: any[]) => {
            if (!form || !data)
                return;

            var sizes = [15, 50, 100, 500, 2500];

            var size: UntypedFormControl = form.controls.employeAmount as any;

            size.setValue(sizes[parseInt(event.value)])

            form.updateValueAndValidity();
        }
    })
    sizeLicense: string;

    @InputProp()
    sizeList: any[] = [
        { value: 0, name: 'Micro' },
        { value: 1, name: 'Pequeña' },
        { value: 2, name: 'Mediana' },
        { value: 3, name: 'Grande' },
        { value: 4, name: 'Ilimitada' }
    ];


    @InputProp({
        placeholder: 'Tipo de Licencia',
        inputType: InputType.SELECT,
        validators: [],
        selectConfig: {
            propName: 'licenseTypeList',
            value: 'value',
            name: 'name'
        }

    })
    licenseType: number;

    @InputProp()
    licenseTypeList: any[] = [
        { value: 0, name: 'Prueba' },
        { value: 1, name: 'Asociado' },
        { value: 2, name: 'Cliente' }];



    initNewForm(item: FormClass) {
        var formGroup = this.initForm(item);

        return formGroup;
    }

    initEditForm(item: FormClass) {
        var formGroup = this.initForm(item);

        var aviableEmployees = formGroup.get('employeAmount').value
        var opt;

        if (aviableEmployees <= 15)
            opt = 0;
        if (aviableEmployees > 15 && aviableEmployees <= 50)
            opt = 1;
        if (aviableEmployees > 50 && aviableEmployees <= 100)
            opt = 2;
        if (aviableEmployees > 100 && aviableEmployees <= 500)
            opt = 3;
        if (aviableEmployees > 500)
            opt = 4;

        formGroup.get('sizeLicense').patchValue(opt);

        return formGroup;
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedFilterService {

  constructor() { }

  private filterSubject = new BehaviorSubject<any>(null);
  
  filterData$ = this.filterSubject.asObservable();

  updateFilter(filterData: any) {
    this.filterSubject.next(filterData);
  }
}

import { Component, Inject, Input, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FormData } from '../../_models/form.model';
import { PDFDocument } from 'pdf-lib'

import { saveAs } from 'file-saver';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Router } from '@angular/router';


declare var html2canvas;
declare var jsPDF;

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent {

    @Input()
    selectActions = {};
    //Indicar entrada de datos solamente

    @Input()
    inputActions = {};

    @Input()
    helpActions = {};

    @Input()
    template

    @Input()
    form: UntypedFormGroup = null

    minDate = new Date(1900, 0, 1);

    auto;

    currentFile = null;


    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<FormDialogComponent>,
        private snackBar: MatSnackBar,
        private el: ElementRef,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: FormData
    ) {

        this.form = data.group;
        this.selectActions = data.selectActions || {};
        this.inputActions = data.inputActions || {};
        this.helpActions = data.helpActions || {};

        dialogRef.disableClose = true;
    }

    openManual(url: string) {
        window.open(url, '_blank');
    }

    openPeriodForm() {
        this.dialogRef.close();
        this.router.navigate(['/period']);
    }

    downloadFormatErrorsTxt() {
        const errors = this.data.errors;

        // Convertir el array de errores en un string legible
        const content = errors.join('\n'); // Une cada error con un salto de línea

        // Crear un blob con el contenido en formato de texto
        const blob = new Blob([content], { type: 'text/plain' });

        // Crear un enlace temporal para descargar el archivo
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Errores al cargar el formato.txt'; // Nombre del archivo

        // Agregar el enlace al DOM, hacer click en él y luego eliminarlo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async saveData(value?: any) {

        if (this.form.invalid)
            return;

        var info = this.form.getRawValue();

        if (this.data.checkValues) {
            var data = this.data.checkFunction(this.data, info);

            if (data.errors && data.errors.length) {

                var errorData = {
                    name: "Error",
                    message: "El formulario contiene los siguientes errores: ",
                    noCancel: true,
                    errors: data.errors
                }

                await this.dialog.open(FormDialogComponent, { data: errorData, panelClass: 'panel-nooverflow' }).beforeClosed().toPromise()

            } else {
                this.dialogRef.close(data);
            }

            /*  var flag: boolean = true;
             var response : any;
 
             Object.keys(this.data.checkValues).forEach(x => {
                 if (typeof this.data.checkValues[x] === 'string') {
 
                     var str1: string = this.data.checkValues[x];
                     var str2: string = info[x];
                     
                     if(!str1){
                         //omitir
                         return;
                     }
                     
                     //Eliminar acentos si existen
                     str1 = str1.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                     str2 = str2.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
 
                     
                     if (!(str1.toLocaleUpperCase() == str2.toLocaleUpperCase())) {
                         flag = false;
                     }
 
                 } else if (this.data.checkValues[x] != info[x]) {
                     flag = false;
                 }
             })
 
             if (flag) {
                 this.dialogRef.close({
                     post: info.post,
                     motherLastName: this.data.checkValues.motherLastName,
                     fatherLastName: this.data.checkValues.fatherLastName,
                 })
             } else {
                 var errorData = {
                     name : "Error",
                     message : "Datos erroneos, intente de nuevo",
                 }
                 var d = await this.dialog.open(FormDialogComponent, {data : errorData, panelClass : 'panel-nooverflow'}).beforeClosed().toPromise()
                 
             } */
        } else {
            if (value)
                this.dialogRef.close({ form: this.form, value: value });
            else
                this.dialogRef.close(this.form)
        }

    }

    confirmDelete() {
        this.dialogRef.close(true);
    }

    close(value?: any) {
        this.dialogRef.close(value);
    }

    closeWindow() {
        window.close();
    }

    getControls() {
        //Regresa los nombres asignados a cada control (no estan definidos con atributo name), para iterarlos
        return Object.keys(this.form.controls).map((k) => {
            var formControl = this.form.controls[k];
            formControl['name'] = k; //Agregar atributo name para poder iterar
            return formControl;
        })
    }

    onLoadSelectData(control) {
        control.selectConfig.dataService.getAll(control.selectConfig.paging)
            .toPromise()
            .then(r => {
                control.selectConfig.data = r.items;
                control.selectConfig.paging = r.paging;
            })
            .catch()

    }

    getTemplate() {
        return this.data ? this.data['template'] : null;
    }

    openModal(callback: any) {
        callback(this.dialog);
    }


    closeDialog() {
        this.dialogRef.close();
    }

    async validInput(control: UntypedFormControl, form: UntypedFormGroup) {

        if (!this.inputActions)
            return

        if (!this.inputActions[(control as any).name]) {
            return
        }
        var confirm = await this.inputActions[(control as any).name](control, form);

        if (!confirm) {
            control.patchValue(!control.value)
        }
    }

    setFile(event, control: UntypedFormControl) {
        if (!event || !event.target || !event.target.files || event.target.files.length == 0)
            return;

        if ((control as any).onChange) {
            var res = (control as any).onChange(event.target.files[0])
            var type = (control as any).fileConfig.type
            var size = (control as any).fileConfig.uploadLimit

            if (res == 0) {
                control.patchValue(event.target.files[0]);
            } else if (res > 0) {
                var message = '';

                switch (res) {
                    case 1:
                        message = `El archivo no es ${type}. Por favor, suba otro con el formato correcto.`
                        break;
                    case 2:
                        message = `El archivo excedió el limite de ${size} MB.`
                        break;
                }

                var errorData = {
                    name: "Error",
                    message: message,
                    noCancel: true
                }
                this.dialog.open(FormDialogComponent, { data: errorData }).afterClosed().toPromise()
            }

        } else {
            control.patchValue(event.target.files[0]);
        }

    }

    isRequired(control: UntypedFormControl) {
        const validator = control.validator ? control.validator({} as AbstractControl) : '';

        if (validator && validator.required)
            return true;
        else
            return false;
    }

    htmlToPdf() {
        const canvas = document.getElementById("htmlToFile");
        var pdf = new jsPDF('p', 'mm', "a4");

        html2canvas(canvas, { scale: 1, scrollY: -window.scrollY, scrollX: 0 })
            .then(async (canvas : HTMLCanvasElement) => {
                canvas.toBlob(async (res) => {
                    const pdfDoc = await PDFDocument.create()

                    const pngImage = await pdfDoc.embedPng(await new Response(res).arrayBuffer())

                    const page = pdfDoc.addPage()

                    page.drawImage(pngImage, {
                        x: 0,
                        y: 0,
                        width: pngImage.width,
                        height: pngImage.height
                    })

                    var data = await pdfDoc.save()

                    let blob = new Blob([data]);

                    saveAs(blob, 'me.pdf');

                }, "image/png", 1)

                /* var imgWidth = 210;
                var pageHeight = 280;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = Math.ceil(imgHeight);
                var topPadding = 20
                var position = 0;

                pdf.addImage(imagePDF, "PNG", 0, 0, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imagePDF, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save("test.pdf"); */
            })
    }

    withPDFLIB()
    {

    }

    toArray(message : any){
        if(Array.isArray(message))
            return message
        else{
            return [message]
        }
    }

    saveLastValue(value : any, control: any, event : any)
    {
        if(control.selectConfig.onAllSelectClose)
        {
            var select : MatSelect = event.source;
            if(value == 0
                ||
                Array.isArray(value) 
                && value.length === 1 
                && value[0] === 0
            )
                select.close()
        }

        if(!value) return
        else 
            control.lastValue = value;
    }

}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError, EMPTY, BehaviorSubject } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { LicenseDialogComponent } from '../license-dialog/license-dialog';
import { LicenseService } from './license.service';
import { SessionService } from './session.service';
import { FormDialogComponent } from '../_components/form-dialog/form-dialog.component';
import { EndSessionService } from './end-session.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorStatusService } from './error-status.service';
import { error } from 'pdf-lib';


@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    //
    // ─── CONSTRUCTOR ────────────────────────────────────────────────────────────────
    //

    
    errors = {
        //Errores de Administrador de Empresas
        107: "El correo ya está registrado en otro campo de trabajo.",
        110: "No debe haber ningún usuario con este rol asignado para poder eliminarlo.",

        //Errores de Permisos
        500: "Permisos no encontrados.",
        501: "No puedes crear o modificar empresas.",
        502: "No puedes crear o modificar empresas, solo tienes permisos de lectura.",
        511: "No puedes crear o modificar usuarios.",
        512: "No puedes crear o modificar usuarios, solo tienes permisos de lectura.",
        521: "No puedes crear o modificar catálogos.",
        522: "No puedes crear o modificar catálogos, solo tienes permisos de lectura.",
        531: "No puedes crear o modificar cuestionarios.",
        532: "No puedes crear o modificar cuestionarios, solo tienes permisos de lectura.",
        541: "No puedes crear o modificar eventos de seguimiento.",
        542: "No puedes crear o modificar eventos de seguimiento, solo tienes permisos de lectura.",

        //Error de No Contate (sin acceso)
        999: "No tienes los permisos necesarios para acceder a esta información.",

        //Root - Cambio de usuario titular
        100: "El usuario no pertenece al espacio de trabajo del titular.",
        103: "El usuario aún no esta activado.",

        //Error Cuestionario - Información erronea
        154 : "No se encontró ningún empleado con ese RFC o CURP. Por favor comuniquese con su departamento de RH.",

        150 : "No se encontró ningún cuestionario con la llave de acceso proporcionada.",
        151 : "Este cuestionario ya fue resuelto.",
        152 : "No se encontró el periodo solicitado.",
        153 : "No se encontró la encuestra solicitada.",
        155 : "La llave de acceso no posee un formato valido.",
        156 : "La llave de acceso acaba de expirar.",
        157 : "La llave de acceso esta alterada.",
        160 : "Este cuestionario ya fue resuelto.",

        //Envio de política
        611 : "No hay subido ningún archivo de política de prevención."
    }

    private dialog: MatDialog;

    constructor(
        private sessionService: SessionService,
        private endSessionService: EndSessionService,
        private licenseService: LicenseService,
        private snackBar: MatSnackBar,
        private router: Router,
        private errorStatusService : ErrorStatusService,
        private injector: Injector
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Authorization headers only for API urls.
        if (request.url.includes('/api') && !request.url.includes('/api/Questionnaires/cuestions') && !request.url.includes('/api/Questionnaires/solve') &&
            !request.url.includes('/api/Users/password/reset') && !request.url.includes('/api/Users/password/forgotten')
            && !request.url.includes('/api/Questionnaires/validate')) {

            // Set JSON headers.
            /* request = request.clone({
                setHeaders: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                },
            }); */

            // Get current sessions.
            let session = this.sessionService.getSession();
            let token: string = null;

            // Primary token.
            if (session && session.token) {
                token = session.token;
            }


            // Set authorization header if exists.
            if (token != null) {
                var auto = request.headers.get('Authorization');

                if(!auto || (auto && auto.length < 20 ))
                {
                    request = request.clone({
                        setHeaders: {
                            'Authorization': 'Bearer ' + token,
                        },
                    });
                }
            }
        }

        //Check claims
        var currentSession = this.sessionService.getSession()

        // Return response.
        return next
            .handle(request)
            .pipe(
                /* tap((response : any) => {
                    if(response && response.statusText && response.statusText == 'No Content')
                    {
                        this.catchAndShow(999);
                    }
                }), */
                catchError( (_error) => {
                    var status;

                    var message :string = (!_error.message) ? "" : _error.message

                    if (_error && _error.status == 401 && !_error.error 
                            && !message.includes('/api/Questionnaires/solve')
                            && !message.includes('/api/Questionnaires/validate')) {
                        //The session is caducated
                        

                        this.sessionService.logout();
                        this.endSessionService.endSession();

                        if (AppComponent.dialog) {
                            var sessionData = {
                                name: "Sesión Expirada",
                                message: "Su sesión ha expirado. Vuelva a iniciar sesión"
                            }
                            AppComponent.dialog.open(FormDialogComponent, {
                                data: sessionData,
                                panelClass: 'panel-nooverflow'
                            })
                        }
                        this.router.navigate(['/users/login'])
                    }

                    if (_error && _error.error)
                        status = _error.error.code;

                    if (status && status == 420) {
                        //The license is caducated
                        /*this.snackBar.open("No puede realizar operaciones hasta que actualice su licencia", null, {
                            duration: 5000
                        });*/
                        this.licenseService.setLicenseState(0);

                        if (AppComponent.dialog) {
                            AppComponent.dialog.open(LicenseDialogComponent, { data: {} }).afterClosed().toPromise().then(() => {
                                // this.router.navigate(['/users/login']);
                            });
                        }

                    }

                    this.catchAndShow(status);
                    
                    return throwError(_error);

                })
            );
    }

    async catchAndShow(status : any){
        if (status) {
            var catchedError = this.errors[status];

            if (this.errors[status]) {
                if (!this.errorStatusService.getError()) {
                    this.errorStatusService.setError(catchedError)

                    this.dialog = this.injector.get(MatDialog);

                    var messageData = {
                        name: "Error",
                        statusCode: status,
                        message: catchedError,
                        noCancel: true
                    }

                    const dialogRef = this.dialog.open(FormDialogComponent, { data: messageData, panelClass: 'panel-nooverflow', width: 'auto' })

                    var res = await dialogRef.afterClosed().toPromise()

                    this.errorStatusService.setError(null)
                }
            }
        }
    }
}
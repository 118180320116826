// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var version = require('../../package.json').version;

export const environment = {
  production: false,
  //server: "https://evaldev.sithec.com.mx/",
  // server : "https://192.168.0.2:5001/",
  //server: "https://localhost:5001/",
  server: "https://devalua.sithec.com.mx/",
  // server: "https://devalua.sithec.com.mx/",

  version: version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to igno1 zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

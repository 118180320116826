import { ValidatorFn, ValidationErrors, AbstractControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';


export function MaxDate(controlName : string, currentDate? : string, lastDate? : string, final? : boolean, group?: boolean, period? : boolean) : ValidatorFn
{
    return (formGroup : UntypedFormGroup) => {

        const control = formGroup.controls[controlName];

        if(control.value == null)
        {
            return;
        }
        const controlDate = moment(control.value);

        if(!controlDate.isValid())
        {
            return;
        }

        if(lastDate)
        {
            var d = moment(lastDate);
            if(controlDate.isBefore(d, 'day'))
            {
                if(final)
                {
                    control.setErrors({minDate : true});
                }else if(group)
                {
                    control.setErrors({minDateGroup : true});
                }else if(period)
                {
                    control.setErrors({minDatePeriod : true});
                }
                else
                {
                    control.setErrors({minDateSolution : true});
                }
            }
                
        }
        
        const validationDate = moment(currentDate);

        if(controlDate.isAfter(validationDate, 'day'))
            control.setErrors({ maxDate: true });

        return null
    }
}

export function PeriodDates(firstControl : string, secondControl? : string)
{
    return (formGroup : UntypedFormGroup) => {

        const first = formGroup.controls[firstControl];
        const second = formGroup.controls[secondControl];

        if(first.value == "" || second.value == "")
        {
            return;
        }
        const controlDate = moment(first.value);
        const toCompareDate = moment(second.value);

        if(!controlDate.isValid())
        {
            return;
        }

        if(toCompareDate.isBefore(controlDate, 'day'))
        second.setErrors({ noPeriod: true });

        return null
    }
}
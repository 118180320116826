import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-license-dialog',
    templateUrl: './license-dialog.html',
    styleUrls: ['./license-dialog.scss']
})
export class LicenseDialogComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { TableModule } from '../../table/table.module';
import { FormErrorsModule } from '../form_errors/form-errors.module';
import { FormDialogComponent } from './form-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';
import { MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatRadioModule } from '@angular/material/radio';



@NgModule({
    declarations: [
        FormDialogComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCheckboxModule,
        FormErrorsModule,
        MatButtonModule,
        MatSelectModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        TableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        A11yModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatRadioModule
    ],
    exports: [FormDialogComponent, MatDatepickerModule],
    providers: []
})
export class FormDialogModule { }

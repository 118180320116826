import { Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';

export class Post extends FormClass {
    @InputProp()
    postID: number;

    @InputProp({
        placeholder: 'Nombre',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    name: string;


    initNewForm(post: Post) {
        var formGroup = this.initForm(post);
        return formGroup;
    }
    initEditForm(post: Post) {
        var formGroup = this.initForm(post);
        return formGroup;
    }

}

export class PostFilters {
    FilterByName: string;
}

export class PostIncludes {
    area: boolean;
    employees: boolean;
}
<div *ngIf="control && control.invalid && (control.dirty || control.touched)">
    <ng-container [ngSwitch]="getErrors()">
        <span *ngSwitchCase="'required'">*Campo obligatorio.</span>
        <span *ngSwitchCase="'minlength'">*El texto es muy corto.</span>
        <span *ngSwitchCase="'maxlength'">*El texto es demasiado largo.</span>
        <span *ngSwitchCase="'email'">*El email no es valido.</span>
        <span *ngSwitchCase="'min'">*Cantidad invalida</span>
        <span *ngSwitchCase="'max'">*Cantidad invalida</span>
        <span *ngSwitchCase="'pattern'">*RFC invalido</span>
        <span *ngSwitchCase="'mustMatch'">*Las contraseñas no coinciden</span>
        <span *ngSwitchCase="'whitespace'">*Contiene espacios en los extremos</span>
        <span *ngSwitchCase="'maxDate'">*La fecha debe ser anterior o igual a la actual</span>
        <span *ngSwitchCase="'minDate'">*La fecha debe ser posterior o igual a la del último evento</span>
        <span *ngSwitchCase="'minDateSolution'">*La fecha debe ser posterior o igual a la de la solución</span>
        <span *ngSwitchCase="'minDateGroup'">*La fecha debe ser posterior o igual a la de la iniciativa</span>
        <span *ngSwitchCase="'minDatePeriod'">*La fecha debe ser posterior o igual a la del inicio del periodo</span>
        <span *ngSwitchCase="'noPeriod'">*La fecha debe ser posterior o igual a la fecha inicial</span>
    </ng-container>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EndSessionService {

    private sessionEndState: BehaviorSubject<boolean> = new BehaviorSubject(null);

    _sessionEndState: Observable<boolean> = this.sessionEndState.asObservable();

    constructor() {

    }

    endSession() {
        setTimeout(() => {
            this.sessionEndState.next(true);
        }, 100);
    }

    reset()
    {
        this.sessionEndState.next(null);
    }
}
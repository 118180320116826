import { Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';

export class Department extends FormClass {

    @InputProp()
    departmentID: number;

    @InputProp({
        placeholder: 'Nombre',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    name: string;


    initNewForm(item: Department) {
        var formGroup = this.initForm(item);

        return formGroup;
    }

    initEditForm(item: Department) {
        var formGroup = this.initForm(item);

        return formGroup;
    }
}

export class DepartmentFilters {
    FilterByName: string;
}

export class DepartmentIncludes {
    areas: boolean;
    companies: boolean;
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from 'src/app/_shared/_helpers/form-manager.component';
import { environment } from 'src/environments/environment';
import { Survey, SurveyFilters, SurveyIncludes } from './survey.model';


@Injectable()
export class SurveyService extends ServiceBase<Survey, SurveyIncludes, SurveyFilters>{
    url = environment.server + 'api/Surveys';

    constructor(
        protected http: HttpClient
    ) {
        super(Survey);
    }


}
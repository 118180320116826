export class Session {

    token: string;

    companyName: string;

    email: string;

    type? : string;

    companyID? : string;

    //Asociate

    associatedID? : string;

    associatedName? : string;

    associatedLevel? : string;

    //Claims 

    adminOptions? : any;

    isAdmin? : any;

    claimsCompany? : any;

    claimsUser? : any;

    claimsCatalogs? : any;

    claimsQuestionaires? : any;
    
    claimsTraking? : any;

}


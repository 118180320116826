import { Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';
import { PeriodDates } from 'src/app/_shared/validators/max-date';

export class Period extends FormClass {

    @InputProp()
    periodID: number;

    @InputProp({
        placeholder: 'Nombre',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    name: string;

    @InputProp({
        placeholder: 'Estado',
        inputType: InputType.CHECKBOX,
        validators: [],
    })
    status: boolean = true;

    @InputProp({
        placeholder: 'Fecha Inicial',
        inputType: InputType.DATE,
        validators: [Validators.required]
    })
    initDate : string;

    @InputProp({
        placeholder: 'Fecha Final',
        inputType: InputType.DATE,
        validators: []
    })
    endDate : string;

    initNewForm(period: Period) {
        var formGroup = this.initForm(period);
        formGroup.setValidators([PeriodDates('initDate', 'endDate')])
        return formGroup;
    }

    initEditForm(period) {
        var formGroup = this.initForm(period);
        formGroup.setValidators([PeriodDates('initDate', 'endDate')])
        return formGroup;
    }

}
export class PeriodIncludes {
}
export class PeriodFilters {
    filterByName: string;
    filterByStatus: boolean;
    filterByYear : boolean;
    filterByMonth : boolean;
}
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/_shared/_services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    loaderState: Observable<boolean>

    constructor(
        private loaderService: LoaderService
    ) {
        this.loaderState = this.loaderService._loaderState;
    }

    ngOnInit(): void { }
}

import { Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';

export class Area extends FormClass {

    @InputProp()
    areaID: number;

    @InputProp({
        placeholder: 'Nombre',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    name: string;


    initNewForm(item: FormClass) {
        var formGroup = this.initForm(item);

        return formGroup;
    }

    initEditForm(item: FormClass) {
        var formGroup = this.initForm(item);

        return formGroup;
    }
}

export class AreaFilters {
    FilterByName: string;
}

export class AreaIncludes {
    posts: boolean;
    departments: boolean;
}
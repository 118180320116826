import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()

export class VersionService {

    url = environment.server + 'api/Version';

    constructor(
        private http: HttpClient
    ) { }

    getVersion(): Observable<any> {
        return this.http.get(this.url);
    }
}
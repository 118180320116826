import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

import * as jwt_decode from 'jwt-decode';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorStatusService } from './error-status.service';
import { FormDialogComponent } from '../_components/form-dialog/form-dialog.component';

@Injectable()
export class AuthGuard implements CanActivate {

    private dialog: MatDialog;

    constructor(
        private sessionService: SessionService,
        private router: Router,
        private injector : Injector,
        private errorService : ErrorStatusService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        var session = this.sessionService.getSession();

        //Last values
        var data = this.sessionService.getNavStates()

        if(route && route.data && session)
        {
            var value = this.checkView(route);
            var hasAccess = session[value] != '3' || session.isAdmin == 'true';
            if(!hasAccess)
            {
                if(this.router.url == '/')
                    this.router.navigate(['/home']);

                this.noAccessError()
                return false;
            }
        }
        
        if(route.data.topNavbarState)
        {
            this.sessionService.setTopNavState(route.data.topNavbarState);
        }
        

        if (route.data.navState) {
            this.sessionService.setNavState(route.data.navState);
        }

        if (route.data.sessionRequired) {
            if (session) {
                
                if(route.data.adminUser) 
                {
                    if(this.decodeToken(session.token).LicenseType == 'S')
                    {    
                        return true;
                    }
                    else{
                        this.router.navigate(['/home']);
                        return false
                    }
                }else if(this.decodeToken(session.token).LicenseType == 'S')
                {
                    this.router.navigate(['/admin-user']);
                    this.onFalse(data)
                    return false;
                }

                if(route.data.associateAdminUser) 
                {
                    if(route.data.master)
                    {
                        if(this.decodeToken(session.token).LicenseType == 'T' && this.decodeToken(session.token).AssociatedLevel == 0)
                        {    
                            return true;
                        }
                        else{
                            this.router.navigate(['/home']);
                            return false
                        }
                    }else{
                        if(this.decodeToken(session.token).LicenseType == 'T')
                        {    
                            return true;
                        }
                        else{
                            this.router.navigate(['/home']);
                            return false
                        }
                    }

                    
                }else if(this.decodeToken(session.token).LicenseType == 'T')
                {
                    this.router.navigate(['/associates-company']);
                    this.onFalse(data)
                    return false;
                }

                if(route.data.companyAdministratorUser)
                {   
                    if(this.decodeToken(session.token).AdminOptions == 'True')
                    {    
                        return true;
                    }
                    else{
                        this.router.navigate(['/home']);
                        return false
                    }
                }else if(this.decodeToken(session.token).AdminOptions == 'True')
                {
                    this.router.navigate(['/admin-company']);
                    this.onFalse(data)
                    return false;
                }
                
                return true;
            }
            this.router.navigate(['/users/login']);
            return false;
        } else {
            if (!session) {
                return true;
            }
            this.router.navigate(['/home']);
            
            return false;
        }

        

    }

    onFalse(data : any)
    {
        if(data && data.topNavbarState != 0 && data.navState != 0)
        {   
            this.sessionService.setNavState(data.navState)
            this.sessionService.setTopNavState(data.topNavbarState)
        }
    }

    decodeToken(token: string) {
        var decoded: any = jwt_decode(token);
        if (!decoded)
            return null;
        return decoded;
    }

    checkView(route : ActivatedRouteSnapshot ){

        var navView = route.data.topNavbarState

        if(route.data.navState == 1)
            return null

        switch(navView){
            case 1: 
                return 'claimsCatalogs'
            case 2: 
                return 'claimsQuestionaires'
            case 3:
                return 'claimsCompany'
            case 4: 
                return 'claimsTraking'
            default:
                return null;
        }
    }

    async noAccessError() {
        var catchedError = 'No tienes los permisos necesarios para acceder a esta información.'
        this.errorService.setError(catchedError)

        this.dialog = this.injector.get(MatDialog);

        var messageData = {
            name: "Error",
            statusCode : 999,
            message: catchedError,
            noCancel: true
        }

        const dialogRef = this.dialog.open(FormDialogComponent, { data: messageData, panelClass: 'panel-nooverflow' })

        var res = await dialogRef.afterClosed().toPromise()

        this.errorService.setError(null)
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PagingParams } from '../_models/paging.model';
import { QuestionnaireEmployeesFilters, QuestionnaireToSend } from '../_models/questionnaire.model';
import { ResultsFilters } from '../_models/results.model';
import { OrderBy } from '../_models/table.model';

@Injectable()
export class QuestionnaireService {
    url = environment.server + 'api/Questionnaires';

    constructor(
        private http: HttpClient
    ) { }

    /**
     * Enviar email / asignar cuestionario a usuario; dependiendo de la bandera se abré el cuestionario o se manda un email para ser contestado
     * @param {QuestionnaireToSend} settings - Periodo, Tipo de Cuestionario y Empleados a los cuales se aplicará
     * @param {boolean} email - Bandera para enviar un email con el cuestionario (true), o abrilo en una nueva pestaña (false y cuando solo sea un usuario seleccionado)
     */
    sendEmail(settings: QuestionnaireToSend, email: boolean): Observable<any> {

        var headers = new HttpHeaders({
            'Content-Type': 'application/json-patch+json'
        });
        var options = { headers: headers, params: { email: email } } as any;

        return this.http.post(this.url, settings, options);
    }

    /**
     * Obtener el pdf de resultado en formato blob
     * @param {number} typeSurvey - Id del tipo de cuestionario
     * @param {number} id  - Id del empleado
     */
    getAnswers(typeSurvey: number, id: number, asHTML?: boolean) {
        var survey: string;
        switch (typeSurvey) {
            case 1:
                survey = '/ats_report/'
                break;
            case 2:
                survey = '/rp_report/'
                break;
            case 3:
                survey = '/eo_report/'
                break;
        }
        return this.http.get(this.url + survey,
            {
                params:
                {
                    'QId': id as any,
                    /* 'OnlyHtml' : ((asHTML) ? asHTML : false) as any,
                    'OnlyData' : ((asHTML) ? false : true) as any */
                },
                responseType: 'blob'
            }
        );
    }

    getGeneralReport(typeSurvey: number, category: boolean, domain: boolean, filters?: ResultsFilters): Observable<any> {
        var survey: string;
        switch (typeSurvey) {
            case 1:
                survey = '/general_ats_report'
                break;
            case 2:
                if (category)
                    survey = '/general_rp_report/categories'
                else if (domain) {
                    survey = '/general_rp_report/domains'
                }
                else
                    survey = '/general_rp_report'
                break;
            case 3:
                if (category)
                    survey = '/general_eo_report/categories'
                else if (domain) {
                    survey = '/general_eo_report/domains'
                }
                else {
                    survey = '/general_eo_report'
                }
                break;
        }
        return this.http.get(this.url + survey, {
            params: {
                ...filters as any,
            }
        });
    }

    /**
     * Obtener los resultados por empleado
     * @param {number} typeSurvey - Tipo de cuestionario 
     * @param {boolean} category - Resultados por categoría
     * @param {boolean} domain  - Resultado por dominio
     * @param {ResultsFilters} filters - Filtros generales (departamento, area, etc)
     */
    getSurveyResults(typeSurvey: number, category: boolean, domain: boolean, filters?: ResultsFilters): Observable<any> {
        var survey: string;


        switch (typeSurvey) {
            case 1:
                survey = '/general_ats_report/n'
                break;
            case 2:
                survey = '/general_rp_report/n'
                break;
            case 3:
                survey = '/general_eo_report/n'
                break;
        }

        return this.http.get(this.url + survey, {
            params: {
                ...filters as any,
            }
        });
    }

    /**
     * Obtener las respuestas
     * @param {ResultsFilters} filters - Filtros generales (departamento, area, etc)
     */
    getEmployeeAnswers(filters: ResultsFilters): Observable<any> {
        let params: any = {}

        if (filters.FilterBySurvey)
            params.SurveyID = filters.FilterBySurvey
        if (filters.FilterByPeriod)
            params.PeriodID = filters.FilterByPeriod
        if (filters.workCenterID)
            params.WorkCenter = filters.workCenterID
        if (filters.departmentID)
            params.Departmment = filters.departmentID
        if (filters.postID)
            params.PostID = filters.postID
        if (filters.areaID)
            params.FilterByArea = filters.areaID

        return this.http.get(this.url + "/reportbyAnswers", {
            params: params
        })
    }

    /*
    newQuestionnaire(questionnaire: Questionnaire): Observable<any> {

        var headers = new HttpHeaders({
            'Content-Type': 'application/json-patch+json'
        });
        var options = { headers: headers };

        return this.http.post(this.url, questionnaire, options);
    }
    
    getQuestionnaire(id: number, includes?: QuestionnaireIncludes): Observable<any> {
        return this.http.get(this.url + '/' + id);
    }

    setQuestionnaire(id: number, updatedQuestionnaire: Questionnaire): Observable<any> {
        return this.http.put(this.url + '/' + id, updatedQuestionnaire);
    }

    deleteQuestionnaire(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id);
    }

    getQuestionnaires(paging?: PagingParams, filters?: QuestionnaireFilters): Observable<any> {
        return this.http.get(this.url, {
            params: {
                ...paging as any,
                ...filters as any,
            }
        })
    }*/

    /**
     * Obtener empleados para asignarles cuestionario de un periodo específico
     * @param {PagingParams} paging - Parametros de páginado
     * @param {QuestionnaireEmployeesFilters} filters - Filtros para empleados
     * @param {OrderBy} orderBy - Parametros de ordenado 
     */
    getEmployeesToSend(paging?: PagingParams, filters?: QuestionnaireEmployeesFilters, orderBy?: OrderBy[]): Observable<any> {
        return this.http.get(this.url + '/employees', {
            params: {
                ...paging as any,
                ...filters as any,
                orderBy: (orderBy) ? JSON.stringify(orderBy) : null
            }
        })
    }

    /**
     * Recuperar las preguntas para un cuestionario, segun un token de autorizacion
     * @param {string} token - Token de autenticación 
     */
    getQuestions(token: string) {
        var headers: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(this.url + '/cuestions', {
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
        })
    }

    /**
     * Autenticar empleado para realizar cuestionario
     * @param {string} token - Contiene información relevante del empleado 
     */
    validate(token: string) {
        return this.http.get(this.url + '/validate',
            {
                headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
            })
    }

    validateKey(key: string) {
        return this.http.get(this.url + '/' + key + '/validate')
    }

    /**
     * Envia las respuestas del empleado 
     * @param {string} token - Token para autenticar la sesión de resolución de cuestionario
     * @param {array} responses - Array de respuestas 
     * @param authData - Objecto con información para autenticar al empleado
     * @param aplicator - Nombre del aplicador del cuestionario, si es que existe
     * @param license - Licencia del aplicador del cuestionario, si es que existe
     */
    solve(token: string, responses, authData, aplicator, license) {
        var headers: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(this.url + '/solve',
            {
                responses: responses,
                post: authData.post,
                motherLastName: authData.motherLastName,
                fatherLastName: authData.fatherLastName,
                applicatorName: aplicator,
                applicatorProfessionalLicense: license
            },
            {
                headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
            })
    }

    downloadFormat(surveyID: number, periodID: number, applyTo: any[]): Observable<any> {
        return this.http.post(this.url + '/document',
            {
                SurveyID: surveyID,
                PeriodID: periodID,
                ApplyTo: applyTo
            }, { responseType: "arraybuffer" })
    }

    uploadResults(typeSurvey: number, periodID: number, replace: boolean, files: FormData): Observable<any> {
        var guide = '';
        switch (typeSurvey) {
            case 1:
                guide = 'ats';
                break;
            case 2:
                guide = 'rp';
                break;
            case 3:
                guide = 'eo';
                break;
        }

        return this.http.post(this.url + '/document/' + guide, files, {
            params: {
                replace: replace,
                periodID: periodID
            } as any
        });
    }

    getMassiveClientLink(periodID: any, surveyID: any): Observable<any> {
        return this.http.post(this.url + '/aplication_masive', {
            surveyID: surveyID,
            periodID: periodID
        })
    }

    validateMassiveClientLink(key: string, isRFC: boolean, value: string): Observable<any> {
        return this.http.post(this.url + '/s', {
            key: key,
            validationType: isRFC,
            validationValue: value,
        })
    }
}
import { UntypedFormGroup } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';

export class QuestCheckMark extends FormClass {


    @InputProp({
        placeholder: 'Reemplazar los resultados actuales por los cargados.',
        inputType: InputType.CHECKBOX,
        validators: [],
        checkBoxConfig : {
            orientation : 'after'
        },
        style: 'text-align: justify;'
    })
    questCheck : boolean = false;

    initNewForm(check: QuestCheckMark) : UntypedFormGroup {
        var formGroup = this.initForm(check);

        return formGroup;
    }
}
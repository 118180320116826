<div class="message" [ngClass]="getClasses((licenseCaducated | async ))">
    <span *ngIf="(licenseCaducated | async ) == 0">Su licencia ha caducado</span>
    <span *ngIf="(licenseCaducated | async ) >0">Su licencia está por caducar</span>

    <span *ngIf="(licenseCaducated | async ) >0">( {{ ((isTest | async)?.licenseType == 0) ? 'Licencia de cortesía -' : '' }} {{ licenseCaducated | async  }} Días restantes)</span>

    <span *ngIf="link">
        <a [href]="link" download target="_blank"> "Instrucciones para renovar"</a>
    </span>
    <span *ngIf="!link" >
        <a>Solicite a su distribuidor el documento para poder renovar su licencia.</a>
    </span>
</div>
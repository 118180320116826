import { FormClass, InputProp, InputType } from '../../_shared/_helpers/property.helper';
import { Validators } from '@angular/forms';
import { Survey } from 'src/app/surveys/guides/survey/survey.model';
import { QuestionnaireService } from '../_services/questionnaire.service';

export class Questionnaire extends FormClass {
    /* @InputProp() */
    employeeID: number;

    /* @InputProp() */
    name: number;

    /* @InputProp() */
    departmentID: number;

    /* @InputProp() */
    departmentName: string;

    /* @InputProp() */
    email: string;

    /* @InputProp() */
    quessionaireID: number;

    /* @InputProp() */
    solved: boolean

    /* initNewForm(questionnaire: Questionnaire) {
        var formGroup = this.initForm(questionnaire);
        return formGroup
    }

    initEditForm(questionnaire: Questionnaire) {
        var formGroup = this.initForm(questionnaire);
        return formGroup
    } */
}

export class QuestionnaireIncludes {
    employees: boolean;
    period: boolean;
    survey: boolean;
}

export class QuestionnaireFilters {
    period: number;
    survey: number;
    appliedToDepartment: number;
    appliedToWorkCenter: number;
    Solved: number;
}

export class QuestionnaireEmployeesFilters {
    SurveyID: number;
    PeriodID: number;
    WorkCenter?: number;
    Departmment?: number;
    PostID?: number;
    
    FilterByArea?: number;
    FilterByName?: string;
    FilterByFLastName?: string;
    FilterByMLastName?: string;
    FilterByCurp?: string;
    FilterByRfc?: string;
    FilterByCode?: string;
    FilterByEmail?: string;
    FilterByGender?: boolean;
    FilterByStatus?: boolean;
}

export class InitiativeEmployeesFilters {
    SurveyID: number;
    PeriodID: number;
    WorkCenter?: number;
    Departmment?: number;
    PostID?: number;
    
    FilterByArea?: number;
    FilterByName?: string;
    FilterByFLastName?: string;
    FilterByMLastName?: string;
    FilterByCurp?: string;
    FilterByRfc?: string;
    FilterByCode?: string;
    FilterByEmail?: string;
    FilterByGender?: boolean;
    FilterByStatus?: boolean;
    FilterBySurveys?: any;
}

export class QuestionnaireToSend {

    periodID: number;
    surveyID: number;
    applyTo: number[];
}

export class QuestionnaireSelect extends FormClass{


    @InputProp({
        placeholder : 'Encuesta',
        inputType : InputType.SELECT,
        validators : [Validators.required],
        selectConfig: 
        {
            propName : 'surveysList',
            value : 'surveyID',
            name : 'name'
        }
    })
    surveyID: number;

    @InputProp()
    surverysList : Survey[]
    
    @InputProp()
    link : string

    initNewForm(questionnaireSelect : QuestionnaireSelect){
        var formGroup = this.initNewForm(questionnaireSelect)
        return formGroup
    }
}
import { Validators } from '@angular/forms';
import { FormClass, InputProp, InputType } from 'src/app/_shared/_helpers/property.helper';

export class WorkCenter extends FormClass {

    @InputProp()
    workCenterID: number;

    @InputProp({
        placeholder: 'Nombre',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    name: string;

    @InputProp({
        placeholder: 'Descripción',
        inputType: InputType.TEXT,
        validators: [Validators.required]
    })
    description: string;

    @InputProp()
    employeeDataService

    initNewForm(item: FormClass) {
        var formGroup = this.initForm(item);

        return formGroup;
    }

    initEditForm(item: FormClass) {
        var formGroup = this.initForm(item);

        return formGroup;
    }


}

export class WorkCenterFilters {
    FilterByName: string;
    FilterByDescription: string;
    FilterByCompany: string
}

export class WorkCenterIncludes {
    includeEmployees: boolean;
    includeCompany: boolean;
}


<h2>{{title}}</h2>
<input id="cb" type="text" hidden>
<section class="options-container" style="flex-flow: row;">
    <div class="filters" [formGroup]="form">
        <!-- <mat-form-field>
            <mat-label> <span class="required">*</span>Periodos de Evaluación</mat-label>
            <mat-select formControlName="period" (selectionChange)="filterData()">
                <mat-option *ngFor="let period of periods" [value]="period._periodID">
                    {{period._name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Centros de Trabajo</mat-label>
            <mat-select formControlName="workCenter" (selectionChange)="filterData()">
                <mat-option [value]="0">
                    TODOS
                </mat-option>
                <mat-option *ngFor="let workCenter of workCenters" [value]="workCenter._workCenterID">
                    {{workCenter._name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Departamentos</mat-label>
            <mat-select formControlName="department" (selectionChange)="filterData()">
                <mat-option [value]="0">
                    TODOS
                </mat-option>
                <mat-option *ngFor="let department of departments" [value]="department._departmentID">
                    {{department._name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Áreas</mat-label>
            <mat-select formControlName="area" (selectionChange)="filterData()">
                <mat-option [value]="0">
                    TODOS
                </mat-option>
                <mat-option *ngFor="let area of areas" [value]="area._areaID">
                    {{area._name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Puesto</mat-label>
            <mat-select formControlName="post" (selectionChange)="filterData()">
                <mat-option [value]="0">
                    TODOS
                </mat-option>
                <mat-option *ngFor="let post of posts" [value]="post._postID">
                    {{post._name}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

    </div>
    <!-- <div class="applicator">
        <h4 class="on-border">Aplicador</h4>
        <h4 style="margin: 0;">Nombre</h4>
        <span>{{applicator?.applicatorName}}</span>
        <h4 style="margin: 0;">Cédula Profesional</h4>
        <span>{{applicator?.applicatorProfessionalLicense}}</span>
    </div> -->
</section>
<app-table [sectionName]="sectionName" [trackBy]="'employeeID'" [config]="tableConfig" [rows]="rows" [paging]="paging"
    [filters]="filters" [orderBy]="orderBy" (onChanged)="loadEmployeesToSend()"></app-table>

<input hidden type="file" #uploader (change)="uploadFile($event)"
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />